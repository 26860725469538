import React from "react";
import { isEmpty } from "lodash";
import { AiFillEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import helpers from "../../utils/helpers";

const StaticContentTable = ({ staticContentList, user, page, manager }) => {
  const { t } = useTranslation();

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                #
              </th>
              <th scope="col" className="py-3 px-6 text-left">
                {t("STATIC_PAGE_TITLE")}
              </th>
              <th scope="col" className="py-3 text-left px-6">
                {t("O_UPDATED_AT")}
              </th>
              {(user?.role === "admin" || manager?.edit) && (
                <th className="py-3 px-6 text-center" scope="col">
                  {t("O_ACTION")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {staticContentList &&
              staticContentList?.map((item, i) => (
                <tr
                  className="bg-white dark:bg-gray-800 border-b dark:border-gray-700"
                  key={i}
                >
                  <th
                    scope="row"
                    className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className="py-4 px-6 border-r text-left">
                    {item?.title}
                  </td>
                  <td className="py-4 px-6 border-r ">
                    {helpers.formatDateTime(item?.updatedAt)}
                  </td>
                  {(user?.role === "admin" || manager?.edit) && (
                    <td className="py-4 px-6 border-l ">
                      <div className="">
                        <ul className="flex justify-center">
                          <li className="px-2 hover:text-LightBlue py-2 hover:bg-white ">
                            <NavLink
                              to={`/${item?.slug}`}
                              state={{ title: item?.title, pageType: "edit" }}
                              title={t("EDIT")}
                              className="hover:text-blue-700 duration-150 ease-in-out transition text-center"
                              data-bs-toggle="tooltip"
                            >
                              {" "}
                              <AiFillEdit className="cursor-pointer h-5 w-5 text-slate-600" />
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            {isEmpty(staticContentList) ? (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={9}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaticContentTable;
