import { apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import { isEmpty, startCase } from "lodash";
import useToastContext from "../../hooks/useToastContext";
import { AiFillEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import helper from "../../utils/helpers";
import { NavLink } from "react-router-dom";

const Table = ({
  users,
  getAllUsers,
  handleUserView,
  user,
  manager,
  page,
  report,
}) => {
  const { t } = useTranslation();
  const notification = useToastContext();

  const handelStatusChange = async (item) => {
    try {
      const payload = {
        status: item?.status === "inactive" ? "active" : "inactive",
      };
      const path = `${apiPath.changeUserStatus}/${item?._id}`;
      const result = await apiPut(path, payload);
      if (result?.status === 200) {
        notification.success(result.data.message);
        getAllUsers({ statusChange: 1 });
      }

    } catch (error) {
      console.log("error in get all users list==>>>>", error.message);
    }
  };

  const renderCheckbox = (item) => (
    <td className="py-2 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] text-center">
      <label
        className="inline-flex relative items-center cursor-pointer"
        title={`${item?.status === "active" ? "Active" : "Inactive"}`}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          checked={item?.status === "active"}
          onChange={(e) =>
            helper.alertFunction(
              `Are you sure you want to ${e.target.checked ? "activate" : "inactivate"
              } user ?`,
              item,
              handelStatusChange
            )
          }
        />
        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-gradientTo" />
      </label>
    </td>
  );

  const renderViewLink = (item) => (
    <td className="py-2 px-4 border-l">
      <div className="">
        <div className="flex justify-center items-center">
          <NavLink
            onClick={() => handleUserView(item)}
            to="/users/view"
            state={item}
            title={t("O_VIEW")}
            className="px-2 py-2"
          >
            <AiFillEye className="cursor-pointer w-5 h-5 text-slate-600 dark:hover:text-white hover:text-blue-700" />{" "}
          </NavLink>
        </div>
      </div>
    </td>
  );

  const handleBodyData = (item, i) => (
    <tr
      key={i}
      className="bg-white border-b dark:bg-gray-800 dark:border-[#ffffff38]"
    >
      <th
        scope="row"
        className="py-4 px-3 border-r dark:border-[#ffffff38] font-medium text-gray-900  dark:text-white dark:border-[#ffffff38]"
      >
        {i + 1 + 10 * (page - 1)}
      </th>
      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.fullName || "N/A"}
      </td>
      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        <img
          class="w-10 h-10"
          src={
            item?.profilePic ? item?.profilePic : "images/default-product.jpg"
          }
          alt=""
        />
      </td>
      {/* <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
                    {item?.countryCode}
                  </td> */}
      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.mobile}
      </td>

      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.email}
      </td>

      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.userType}
      </td>

    

      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.verificationStatus}
      </td>

      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {item?.status}
      </td>

      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        N/A
      </td>

      
      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
      {item?.businessName||"N/A"}
      </td>


      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] ">
        {helper.formatDateTime(item?.lastLogin)}
      </td>
      <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38]">
        {helper.formatDateTime(item?.lastView)}
      </td>

      {(manager?.add || manager?.edit || user?.role === "admin") &&
        !report &&
        renderCheckbox(item)}
      {report && (
        <td className="py-4 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] text-center">
          {startCase(item?.status) || "N/A"}
        </td>
      )}
      {/* {(manager?.view || user?.role === "admin") &&
        !report &&
        renderViewLink(item)} */}
    </tr>
  );

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400 dark:border-[#ffffff38]">
            <tr>
              <th scope="col" className="py-3 px-3">
                {t("S.NO")}
              </th>

              <th scope="col" className="py-3 px-6">
                {t("O_FULL_NAME")}
              </th>

              <th scope="col" className="py-3 px-6">
                {t("PROFILE_PICTURE")}
              </th>

              <th scope="col" className="py-3 px-6">
                <div className="text-left">{t("O_MOBILE_NUMBER")}</div>
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                {t("O_EMAIL")}
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                User Type
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                Profile Status
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                Account Status
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                Admin Designation
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                Admin Business Name
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                {t("LAST_LOGIN")}
              </th>

              <th scope="col" className="py-3 px-6 text-left">
                {t("LAST_VIEWED")}
              </th>

          

              <th scope="col" className="py-3 px-6 text-left">
              Actions
              </th>

              {/* {(manager?.view || user?.role === "admin") && !report && (
                <th scope="col" className="py-3 px-6 text-left">
                  {t("O_ACTION")}
                </th>
              )} */}

            </tr>
          </thead>
          <tbody>
            {users?.length > 0 &&
              users?.map(
                (item, i) => handleBodyData(item, i)

              )}
            {isEmpty(users) ? (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td
                  className="py-2 px-4 border-r dark:border-[#ffffff38]"
                  colSpan={13}
                >
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
