import React, { useContext, useEffect, useState } from "react";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import Pagination from "../Pagination";
import AuthContext from "../../context/AuthContext";
import dayjs from "dayjs";
import ODateRangePicker from "../../components/shared/datePicker/ODateRangePicker";
import { useTranslation } from "react-i18next";
import CategoryTable from "./Table";
function Home() {
  const { t } = useTranslation();
  const { logoutUser, user, updatePageName } = useContext(AuthContext);
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10,
  });
  const [addShowModal, setShowModal] = useState(false);
  const [users, setAllUser] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [item, setItem] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sort, setSort] = useState({
    sortKey: "createdAt",
    sortType: "desc",
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);

  const [filterData, setFilterData] = useState({
    category: "",
    searchkey: "",
    startDate: "",
    endDate: "",
    isReset: 0,
    isFilter: false,
  });

  const handleResultProcessing = (data) => {
    const response = data?.results;
    const resultStatus = data?.success;

    setAllUser(response?.docs);

    if (resultStatus) {
      setPaginationObj({
        ...paginationObj,
        page: response.page,
        pageCount: response.totalPages,
        perPageItem: response?.docs.length,
        totalItems: response.totalDocs,
      });
    }
  };
  const manager =
    user?.permission?.find((e) => e.manager === "notification-manager") ?? {};
  const handlePageUpdate = () => {
    if (users?.length <= 1) {
      setPage(page - 1);
      setIsDelete(true);
      setPaginationObj({ ...paginationObj, page: page - 1 });
    }
  };

  const handleError = (error) => {
    console.log("error ", error);
    setPaginationObj({});

    if (error?.response?.status === 401 || error?.response?.status === 409) {
      logoutUser();
    }
  };

  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPage(newPage);
  };
  const getAllNotification = async (data) => {
    try {
      const { searchkey } = filterData;
     
      const payload = {
        page,
        pageSize,
        keyword: searchkey,
      };
      const path = apiPath.getSearchList;
      const result = await apiGet(path, payload);
      if (result?.status === 200) {
        handleResultProcessing(result.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    getAllNotification();
  }, [page, filterData, sort]);

  const handleReset = () => {
    setFilterData({
      category: "",
      searchkey: "",
      startDate: "",
      endDate: "",
      isReset: filterData?.isReset + 1,
      isFilter: false,
    });
    setPage(1);
    setIsDelete(true);
    setSearchTerm("");
  };

  const handleDateChange = (start, end) => {
    setPage(1);
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isFilter: true,
    });
    setIsDelete(true);
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: 0,
        searchkey: debouncedSearchTerm || "",
        isFilter: !!debouncedSearchTerm,
      });
      setPage(1);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    console.log("addShowModal :>> ", addShowModal);
  }, [addShowModal]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const handleUserView = (userItem) => {
    setItem(userItem);
    setViewShowModal(true);
    updatePageName(` ${t("VIEW") + " " + t("NOTIFICATION_MANAGER")}`);
  };
  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            <div className="border-b border-b-[#E3E3E3] grid  xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 py-3">
              <div className="col-span-2 flex flex-wrap  items-center w-full">
                <div className="flex  justify-between lg:pt-0 pt-3 flex-wrap mb-2 w-full">
                  <section className="flex">
                    <div className="relative flex items-center mb-3">
                      <div className="absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none"></div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={t("O_SEARCH_NAME")}
                        value={searchTerm}
                        title=""
                        required
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {!searchTerm ? (
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 text-[#A5A5A5] dark:text-gray-40 absolute right-[10px]"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      type="button"
                      onClick={handleReset}
                      className="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                    >
                      {t("O_RESET")}
                    </button>
                  </section>
                </div>
              </div>
            </div>
            <CategoryTable
              notifications={users}
              user={user}
              getAllNotification={getAllNotification}
              paginationObj={paginationObj}
              page={page}
              setSort={setSort}
              sort={sort}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
   
    </div>
  );
}
export default Home;
