import { useContext, useEffect, useState } from "react";
import { apiGet, apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import ReactQuill from "react-quill";
import useToastContext from "../../hooks/useToastContext";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import OInputField from "../../components/reusable/OInputField";
import formValidation from "../../utils/formValidation";
import { preventMaxInput } from "../../utils/validations";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: "onChange",
    defaultValues: {
      title: location?.state?.title,
    },
  });

  const [content, setContent] = useState("");
  const [pageType] = location?.state?.pageType;
  const notification = useToastContext();
  const { logoutUser, updatePageName, user } = useContext(AuthContext);

  const manager =
    user?.permission?.find((e) => e.manager === "static_contents_manager") ??
    {};

  const updateStaticContent = async () => {
    try {
      const formValue = getValues();
      const data = {
        title: formValue?.title?.trim(),
        content,
      };
      const path = apiPath.getStaticContent + "?" + "slug=terms-and-conditions";
      const result = await apiPut(path, data);
      if (result?.status === 200) {
        notification.success(result?.data?.message);
        getStaticContentList();
      }
    } catch (error) {
      console.error("error in get all users list==>>", error.message);
    }
  };

  const getStaticContentList = async () => {
    try {
      const path = apiPath.getStaticContent;
      const payload = {
        slug: "terms-and-conditions",
      };
      const result = await apiGet(path, payload);
      if (result?.status === 200) {
        const response = result?.data?.results.docs[0];
        setContent(response?.content);
      }
    } catch (error) {
      console.error("error in get all FAQs list==>>", error.message);

      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  };
  useEffect(() => {
    updatePageName(t("NAV_TERMS_AND_CONDITIONS"));
  }, []);
  useEffect(() => {
    getStaticContentList();
  }, []);

  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white rounded-lg border border-[#E9EDF9]">
            <div className=" border-b-[#E3E3E3] grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 ">
              <div className="col-span-2 flex flex-wrap  items-center" />
            </div>

            <div className="p-5 shadow-md">
              <OInputField
                errors={errors}
                inputLabel={
                  <>
                    {t("STATIC_PAGE_TITLE")}
                    <span className="text-red-500">*</span>
                  </>
                }
                wrapperClassName="relative z-0 mb-6 w-full group"
                name="title"
                maxLength={200}
                onInput={(e) => preventMaxInput(e, 200)}
                type="text"
                autoFocus
                register={register("title", formValidation["title"])}
              />
              <ReactQuill value={content} onChange={setContent} />
            </div>
          </div>
          {/* <p style={{ marginTop: '10px', marginLeft: '10px' }}>
            {t('LAST_UPDATE')}:- {dayjs(updateDate).format('llll')}
          </p> */}
          {pageType === "e" && (
            <div className="xl:ml-0 2xl:ml-auto lg:pt-0 pt-2">
              <div className="flex">
                {(manager?.edit || user?.permission?.length === 0) && (
                  <button
                    className="bg-gradientTo text-sm px-8 mt-3 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                    title={t("O_SAVE")}
                    onClick={handleSubmit(updateStaticContent)}
                  >
                    {t("O_SAVE")}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
