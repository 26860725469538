import OImage from "../../components/reusable/OImage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import defaultImage from "../../assets/images/No-image-found.jpg";
import { isEmpty, startCase } from "lodash";
import firstNameIcon from "../../assets/icons/icon/user-id.svg";
import emailIcon from "../../assets/icons/icon/emailIcon.svg";
import birthIcon from "../../assets/icons/icon/birthIcon.svg";
import dateIcon from "../../assets/icons/icon/dateIcon.svg";

import interestIcon from "../../assets/icons/icon/interests.svg";

import helper from "../../utils/helpers";
import {
  FaSquareInstagram,
  FaTwitter,
  FaLinkedin,
  FaFacebook,
} from "react-icons/fa6";
const UserView = ({ items }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [item] = useState(location.state);
  const [age, setAge] = useState(null);
  useEffect(() => {
    helper.calculateAge(item?.dob);
    setAge(helper.calculateAge(item?.dob));
  }, [item]);

  console.log(age, "age");

  const renderSocialIcon = (socialLinkType, socialLink) => {
    const handleClick = () => {
      window.location.href = socialLink;
    };
    switch (socialLinkType) {
      case "Instagram":
        return (
          <FaSquareInstagram
            onClick={handleClick}
            className="text-pink-500 w-full h-full"
          />
        );
      case "Twitter":
        return <FaTwitter onClick={handleClick} className="text-blue-500" />;
      case "Facebook":
        return <FaFacebook onClick={handleClick} className="text-blue-800" />;
      case "LinkedIn":
        return <FaLinkedin onClick={handleClick} className="text-blue-800" />;
      default:
        return null;
    }
  };
  const ProfileInfo = () => {
    return (
      <>
        <div className="flex items-center">
          <div className="flex mr-5">
            <figure className="inline-block overflow-hidden rounded-full border border-2 mb-5">
              <OImage
                src={item?.profilePic || defaultImage}
                className="w-[100px] h-[100px] inline"
                alt=""
              />
            </figure>
          </div>

          <div className="grid grid-cols-4 bg-[#F2F2F2] rounded-lg p-4 w-[calc(100%_-_135px)] mr-4 px-8 flex justify-between">
            <div className="flex items-center">
              <figure className="bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5 C5C5C]">First Name</span>
                <strong className="text-sm">
                  {" "}
                  {startCase(item?.firstName) || "N/A"}
                </strong>
              </figcaption>
            </div>

            <div className="flex items-center">
              <figure className="bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5 C5C5C]">Last Name</span>
                <strong className="text-sm">
                  {" "}
                  {startCase(item?.lastName) || "N/A"}
                </strong>
              </figcaption>
            </div>

            <div className="flex items-center">
              <figure className="bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={emailIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5 C5C5C]">Email Address</span>
                <strong className="text-sm"> {item?.email || "N/A"}</strong>
              </figcaption>
            </div>

            {/* <div className="flex items-center ml-10">
              <figure className="bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={mobileIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5 C5C5C]">Mobile Number</span>
                <strong className="text-sm">
                  {" "}
                  {item && item.mobileNumbers ? (
                    item.mobileNumbers.map((number, i) => (
                      <div key={i}>
                        +{item.countryCode}
                        {number.phoneNumber}
                      </div>
                    ))
                  ) : (
                    <span>{"N/A"}</span>
                  )}
                </strong>
              </figcaption>
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const UserDetails = ({ translate }) => {
    return (
      <>
        <div className="user-details">
          <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full">
            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("O_USER_ID")}
                </span>
                <strong className="text-sm">{item?.userId ?? "N/A"}</strong>
              </figcaption>
            </div>

            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("JOB_TITLE")}
                </span>
                <strong className="text-sm">{item?.jobTitle ?? "N/A"}</strong>
              </figcaption>
            </div>

            
            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("OFFERS_TITLE")}
                </span>
                <strong className="text-sm">{item?.title ?? "N/A"}</strong>
              </figcaption>
            </div>

            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("TAGS")}
                </span>
                <strong className="text-sm">
                  {item?.tags.join(",") || "N/A"}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SubscriptionInfo = ({ translate }) => {
    return (
      <>
        <div className="user-details">
          <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full">
            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("O_AGE")}
                </span>
                <strong className="text-sm">
                  {(
                    <>
                      {age} {"years"}
                    </>
                  ) || "N/A"}
                </strong>
              </figcaption>
            </div>

            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={firstNameIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("REGISTRATION_VIA")}
                </span>
                <strong className="text-sm">
                  {startCase(item?.registrationType) ?? "N/A"}
                </strong>
              </figcaption>
            </div>
            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={dateIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("REGISTERED_DATE")}
                </span>
                <strong className="text-sm">
                  {helper.formatDateTime(item?.createdAt)}
                </strong>
              </figcaption>
            </div>
            <div className="flex items-center mb-4">
              <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
                <img src={dateIcon} alt="" />
              </figure>
              <figcaption className="w-[calc(100%_-_41px)]">
                <span className="block text-[#5C5C5C]">
                  {translate("SUMMARY")}
                </span>
                <strong className="text-sm">
                  {startCase(item?.summary) || "N/A"}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    );
  };

  const VerificationStatus = ({ translate }) => {
    return (
      <div className="user-details">
        <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full">
          <div className="flex items-center mb-4">
            <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
              <img src={birthIcon} alt="" />
            </figure>
            <figcaption className="w-[calc(100%_-_41px)]">
              <span className="block text-[#5C5C5C]">
                {translate("USER_DOB")}
              </span>
              <strong className="text-sm">
                {item?.dob ? <>{helper.formatDate(item?.dob)}</> : "N/A"}
              </strong>
            </figcaption>
          </div>

          <div className="flex items-center mb-4">
            <figure className="bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3">
              <img src={interestIcon} alt="" />
            </figure>
            <figcaption className="w-[calc(100%_-_41px)]">
              <span className="block text-[#5C5C5C]">
                {translate("ADDRESSES")}
              </span>
              <strong className="text-sm">{item?.addresses ?? "N/A"}</strong>
            </figcaption>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-5 dark:bg-slate-900">
      <div className="">
        <ProfileInfo />
        <div className="grid grid-cols-3 gap-5">
          <UserDetails translate={t} />
          <VerificationStatus translate={t} />
          <SubscriptionInfo translate={t} />
        </div>
        <div className="flex mb-5">
          <div class="flex-1 mr-4">
            <div className="mt-10 mb-2 dark:text-white">
              <div>
                <ul>
                  <li>
                    <strong>{t("WEBSITE_SOCIAL")}</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dark:text-white">
              <div className="user-details">
                <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full min-h-[98px]">
                  <ul className="flex flex-wrap items-center w-full">
                    {item?.socialNetwork?.length > 0 &&
                      item?.socialNetwork?.map((socialNetworkData, index) => (
                        <li key={index} className="w-[39px] mr-[14px]">
                          {renderSocialIcon(
                            socialNetworkData.socialLinkType,
                            socialNetworkData.socialLink
                          )}{" "}
                        </li>
                      ))}
                  </ul>

                  {isEmpty(item?.socialNetwork) && (
                    <span className="block text-center mt-[10px]">
                      {t("O_NO_RECORD_FOUND")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 mr-4">
            <div className="mt-10 mb-2 dark:text-white">
              <div>
                <ul>
                  <li>
                    <strong>{t("EMAIL_IDS")}</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dark:text-white">
              <div className="user-details">
                <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full pb-4 min-h-[98px]">
                  <ul className="w-full">
                    {item?.emails?.length > 0 &&
                      item?.emails?.map((email, index) => (
                        <li className="">
                          <span>
                            <div key={index}>{email.email}</div>
                          </span>
                        </li>
                      ))}
                  </ul>
                  {isEmpty(item?.emails) && (
                    <span className="block text-center mt-[10px]">
                      {t("O_NO_RECORD_FOUND")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 mr-4">
            <div className="mt-10 mb-2 dark:text-white">
              <div>
                <ul>
                  <li>
                    <strong>{t("MOBILE_NUMBERS")}</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dark:text-white">
              <div className="user-details">
                <div className="border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full pb-4 pt-2 min-h-[98px]">
                  <ul style={{ width: "100%" }}>
                    {item?.mobileNumbers?.length > 0 &&
                      item?.mobileNumbers?.map((number, index) => (
                        <li className="">
                          <span>
                            <div key={index}>{number.phoneNumber || "N/A"}</div>
                          </span>
                        </li>
                      ))}
                  </ul>
                  {isEmpty(item?.mobileNumbers) && (
                    <span className="block text-center mt-[28px]">
                      {t("O_NO_RECORD_FOUND")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default UserView;
