import React, { useContext, useEffect, useState } from "react";
import { RiAdminFill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";
import { apiGet } from "../utils/apiFetch";
import pathObj from "../utils/pathObj";
import dayjs from "dayjs";  
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import ODateRangePicker from "../components/shared/datePicker/ODateRangePicker";
import OCountUp from "../components/OCountUp";
import { useNavigate } from "react-router-dom";
const checkUserPermission = (user) => {
  if (!user) {
    return false;
  }

  const localPermissions = [...user.permission];

  if (user.permission.length === 0) {
    return true;
  }

  const perIndex = localPermissions.findIndex(
    (item) => item.manager === "user_manager"
  );

  return perIndex >= 0 && localPermissions[perIndex]?.view;
};
const getPayload = (startDate, endDate) => {
  return {
    startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
    endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
  };
};
function Home() {
  const { t } = useTranslation();
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [isReset, setIsReset] = useState(false);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const getDashboardDetails = async () => {
    try {
      const payload = getPayload(startDate, endDate);
      const path = pathObj.getDashboardDetails;
      const result = await apiGet(path, payload);
      setDashboardDetails({ ...dashboardDetails, ...result.data.results });
    } catch (error) {
      console.log("error:", error);
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, [startDate, endDate]);

  const handleReset = () => {
    setEndDate("");
    setStartDate("");
    setIsReset(!isReset);
  };

  return (
    <>
      <div className="sm:flex items-center text-center sm:text-left px-3 md:px-4 xl:px-7 lg:px-5  py-4 md:py-8 border dark:bg-slate-900">
        <ODateRangePicker
          handleDateChange={handleDateChange}
          isReset={isReset}
          setIsReset={setIsReset}
          place="dashboard"
        />
        <button
          type="button"
          onClick={handleReset}
          className="bg-gradientTo text-sm px-8 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
        >
          {t("O_RESET")}
        </button>
      </div>
      <div className="py-4 px-4 md:px-8 dark:bg-slate-900">
        <div className="sale_report grid pt-10 3xl:grid-cols-3 gap-y-10 gap-4 gap-x-10 2xl:grid-cols-3 sm:grid-cols-2 mb-7 ">
          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/user_manager")}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalUserCount} />
              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                {t("TOTAL_NUMBER_USER")}
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>
          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalActiveUserCount} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                {t("TOTAL_ACTIVE_USERS")}
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>
          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=inactive")
            }
            style={{ cursor: "pointer" } }
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalInactiveUserCount || 0} />
              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                {t("TOTAL_INACTIVE_USERS")}
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <RiAdminFill />
            </span>
          </div>


          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalLearner} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Learner
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          
          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalBusiness} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Business
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>
          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalGuru} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Guru
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalActiveLearner} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Active Learner
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalActiveBusiness} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Active Business
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalActiveGuru} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Active Guru
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalTitle} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Titles
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalViews} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Views
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalRunningCourses} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Running Courses
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalCompletedCourses} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Completed Courses
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalFeedback} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Feedback
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.totalRepliedFeedback} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Replied Feedback
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

          <div
            className="text-center relative  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg py-4 md:py-8 border"
            onClick={() =>
              navigate("/user_manager?status=active")
            }
            style={{ cursor: "pointer" }}
          >
            <h3 className="text-center mb-0 text-slate-900 font-bold md:text-3xl sm:text-lg dark:text-white">
              <OCountUp value={dashboardDetails?.averageRating} />

              <span className="text-base text-neutral-400 font-normal block pt-3 ">
                Total Average Rating
              </span>
            </h3>
            <span className="text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0 absolute right-[-10px] top-[-10px] p-3 border z-10 bg-white">
              <FaUserTie className="h-8 w-8" />
            </span>
          </div>

        </div>
      </div>
    </>
  );
}

export default Home;
