import ErrorMessage from "../../components/ErrorMessage";
import OButton from "../../components/reusable/OButton";
import useToastContext from "../../hooks/useToastContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import { useTranslation } from "react-i18next";
import OInputField from "../../components/reusable/OInputField";
import formValidation from "../../utils/formValidation";
import OTextArea from "../../components/reusable/OTextArea";
import OMultiSelect from "../../components/reusable/OMultiSelect";
import OImage from "../../components/reusable/OImage";
import { AiFillCamera, AiFillCloseCircle } from "react-icons/ai";
import { Dialog } from "@headlessui/react";
import { DialogContent, DialogContentText, Slider } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import AuthContext from "../../context/AuthContext";
const CategoryAdd = ({ getAllNotification, setShowModal }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const notification = useToastContext();
  const [availableFor, setAvailableFor] = useState("all");
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [subAdminSuggestion, setSubAdminSuggestion] = useState([]);
  const [userList, setUserList] = useState([]);
  const [subAdminList, setSubAdminList] = useState([]);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [isRemove, setIsRemove] = useState(false);
  const profilePicRef = useRef();
  const [categoryList, setCategoryList] = useState([])
  const { logoutUser } = useContext(AuthContext);
  const [postImage, setPostImage] = useState(null)

  const handleOptionValues = (res) =>
    res.map((element) => {
      return {
        label: `${element.firstName} ${element.lastName} (${element.userId})`,
        value: element._id,
      };
    });

  const getAllUser = async () => {
    try {
      const path = apiPath.allNotificationUserList;
      const result = await apiGet(path);
      if (result?.status === 200) {
        const response = result?.data?.results;
        const latest = handleOptionValues(response);

        setUserSuggestion(latest);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllSubAdmin = async () => {
    try {
      const path = apiPath.fetchLatestSubAdminList;
      const result = await apiGet(path);
      if (result?.status === 200) {
        const response = result?.data?.results;
        const latest = handleOptionValues(response);
        setSubAdminSuggestion(latest);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectAllUsers = (event) => {
    if (event.target.checked) {
      setAvailableFor("all");
    }
  };

  const [notificationUserError, setNotificationUserError] = useState(false);
  const [notificationSubadminError, setNotificationSubadminError] =
    useState(false);

  const handleParticularUser = (event) => {
    if (event.target.checked) {
      setAvailableFor("particularUser");
    }
  };

  const handleParticularSubAdmin = (event) => {
    if (event.target.checked) {
      setAvailableFor("specificSubAdmin");
    }
  };

  const handelAllSubAdmin = (event) => {
    if (event.target.checked) {
      setAvailableFor("subAdmin");
    }
  };

  const checkUserSelection = () => {
    const isValid = userList?.length !== 0;
    setNotificationUserError(!isValid);
    return isValid;
  };
  const checkSubadminSelection = () => {
    const isValid = subAdminList?.length !== 0;
    setNotificationSubadminError(!isValid);
    return isValid;
  };

  const onSubmit = async (data) => {
    try {
      let formData = new FormData()
      formData.append("name", data?.name)
      formData.append("image", postImage)
      if(data?.categoryId){
        formData.append("categoryId", data?.categoryId)
      }

      const res = await apiPost(apiPath.addCategorySubCategory, formData);
      if (res.data.success) {
        setShowModal(false);
        notification.success(res?.data?.message);
        getAllNotification();
      } else {
        notification.error(res?.data?.message);
      }
    } catch (err) {
      console.error("err:", err);
    }
  };

  useEffect(() => {
    getAllUser();
    getAllSubAdmin();
  }, []);
  let editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    // croppedImg: user?.profilePic,
    isRemove: false,
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
    setOpen(false);
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg,
        isRemove: true,
      });
    }
    setOpen(false);
  };

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setImage(url);
    setPostImage(e.target.files[0])
  };

  const handleCategoryList = async () => {
    try {
      const path = apiPath.getCategoryKeyValue;
      const result = await apiGet(path);
      if (result?.status === 200) {
        setCategoryList(result?.data?.results)
      }
    } catch (error) {
      console.log("error==>", error)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  }

  useEffect(()=>{
    handleCategoryList()
  },[])


  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div
            className="relative my-6 mx-auto w-full"
            style={{ width: "35%" }}
          >
            <div className="sm:py-4 sm:px-2 py-8 px-7 ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {t("ADD_CATEGORY")}
                  </h3>
                  <button
                    className=" ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-[#B8BBBF]  text-4xl " title="Close">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="">
                    <div className="px-2">
                      <OInputField
                        wrapperClassName=""
                        name="name"
                        inputLabel={
                          <>
                            {t("O_TITLE")}
                            <span className="text-red-500">*</span>
                          </>
                        }
                        type="text"
                        autoFocus
                        register={register("name", formValidation.name)}
                        errors={errors}
                      />
                    </div>

                    <div className="px-2">
                    <OInputField
                      wrapperClassName="relative z-0   w-full group"
                      name="country_code"
                      inputLabel={'Select Category'}
                      errors={errors}
                      type="select"
                      register={register("categoryId")}
                      selectOptions={[
                        <option value="" key="select_code">
                         Select Category
                        </option>,
                        categoryList?.map((category)=>{
                          return(
                        <option value={category?._id} key = {category?._id}>
                         {category?.name}
                        </option>

                          )
                        })
                      ]}/>
                    </div>

                    <div className="px-2">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-28">
                        {t("PROFILE_IMAGE")}
                      </label>
                      <input
                        type="file"
                        accept="image/png,image/jpeg"
                        name="profilePic"
                        // ref={profilePicRef}
                        onChange={handleFileChange}
                        className=""
                      />
                      <OImage
                        src={image || ""}
                        fallbackUrl="/images/user.jpg"
                        className="w-24 h-24"
                        alt=""
                        style={{ borderRadius: "50%" }}
                      />

                    </div>


                  </div>
                </div>
                <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {t("O_BACK")}
                  </button>
                  <OButton
                    label={'Add'}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>

    </div>
  );
};

export default CategoryAdd;
