import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import Documents from "../reusable/icons/Documents";
import { GiPlatform } from "react-icons/gi";
import Dashboard from "../../assets/images/sidebar_icon1.svg";
import SubAdmin from "../../assets/images/subadmin_manager.svg";
import Logout from "../../assets/images/logout.svg";
import { useTranslation } from "react-i18next";
import logoImage from "../../assets/images/logo-color.png";
import { AiFillSetting } from "react-icons/ai";
import Swal from "sweetalert2";

const classNames = require("classnames");

const Sidebar = () => {
  const { t } = useTranslation();
  const { logoutUser, user, sidebarStatus, updatePageName } =
    useContext(AuthContext);

  const getIcon = (icon) => icon && <span className="mr-2">{icon}</span>;

  const generateNavLink = (to, label, icon) => {
    const getNavLinkClasses = (isActive) =>
      classNames(
        "flex items-center px-4 lg:px-7 py-4 hover:bg-sideBarNavActiveColor hover:text-gradientTo",
        {
          "bg-white": isActive,
          "text-black": isActive,
          active: isActive,
        }
      );

    const handleNavLinkClick = () => updatePageName(t(label));

    return (
      <NavLink
        to={to}
        className={({ isActive }) => getNavLinkClasses(isActive)}
        onClick={handleNavLinkClick}
      >
        {getIcon(icon)}
        {t(label)}
      </NavLink>
    );
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#222222",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      handleLogoutConfirm(result, logoutUser);
    });
  };

  const checkSidebarPermission = (arg) => {
    if (!user) {
      return false;
    }

    const localPermissions = [...user.permission];

    if (user.permission.length === 0) {
      return true;
    }

    const perIndex = localPermissions.findIndex((item) => item.manager === arg);

    return perIndex >= 0 && localPermissions[perIndex]?.view;
  };

  return (
    <>
      <div
        className={`sidebar lg:block z-10 ${sidebarStatus === "open" ? "block" : "sidebarHide"
          } bg-gradient-to-t from-gradpurple to-gradientFrom w-[220px] xl:w-[280px] fixed h-full overflow-y-auto`}
      >
        <div className="text-sideBarNavColor">
          <div className="text-sideBarNavColor">
            <Link
              to="/dashboard"
              onClick={() => updatePageName("Dashboard")}
              className="px-2 py-6 w-full text-center flex justify-center"
            >
              <img src={logoImage} className="inline" alt="" />
            </Link>
            <div className="profile text-center">
              <small className="block text-sm">Welcome</small>
              <strong className="block text-lg overflow-hidden text-ellipsis px-2 whitespace-nowrap ">
                {user?.firstName + " " + user?.lastName}
              </strong>
            </div>
            <nav className="pt-4 pb-5 flex flex-col justify-center font-normal text-xs overflow-y-auto">
              {renderNavLinks(checkSidebarPermission, generateNavLink)}
              <Link
                onClick={handleLogout}
                className="flex items-center px-4 lg:px-7 py-4 hover:bg-sideBarNavActiveColor hover:text-gradientTo"
              >
                <span className="mr-2">
                  <img src={Logout} className="max-w-[18px]" alt="logout" />
                </span>
                {t("NAV_LOGOUT")}
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
const handleLogoutConfirm = (result, logoutUser) => {
  if (result.isConfirmed) {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    logoutUser();
  }
};
const renderNavLinks = (checkSidebarPermission, generateNavLink) => (
  <>
    {checkSidebarPermission("dashboard") &&
      generateNavLink(
        "/dashboard",
        "NAV_DASHBOARD",
        <img src={Dashboard} className="max-w-[18px]" alt="" />
      )}

    {
      generateNavLink(
        "/home",
        "HOME_PAGE",
        <Documents style={{ fontSize: "20px" }} />
      )}

    {checkSidebarPermission("user_manager") &&
      generateNavLink(
        "/user_manager",
        "USER_MANAGER",
        <img src={SubAdmin} className="max-w-[18px]" alt="" />
      )}

    {
      generateNavLink(
        "/search",
        "SEARCH_MANAGER",
        <img src={SubAdmin} className="max-w-[18px]" alt="" />
      )
    }

    {
      generateNavLink(
        "/course",
        "COURSES",
        <Documents style={{ fontSize: "20px" }} />
      )}

    {
      generateNavLink(
        "/category",
        "CATEGORY",
        <Documents style={{ fontSize: "20px" }} />
      )}

      {
      generateNavLink(
        "/feedback",
        "FEEDBACK",
        <Documents style={{ fontSize: "20px" }} />
      )}




    {checkSidebarPermission("notification-manager") &&
      generateNavLink(
        "/notification-manager",
        "NOTIFICATION_MANAGER",
        <GiPlatform style={{ fontSize: "20px" }} />
      )}

    {checkSidebarPermission("emailTemplate") &&
      generateNavLink(
        "/emailTemplate",
        "EMAIL_TEMPLATE",
        <AiFillSetting style={{ fontSize: "20px" }} />
      )}


    {checkSidebarPermission("static_contents_manager") &&
      generateNavLink(
        "/static_contents_manager",
        "NAV_STATIC_CONTENTS",
        <Documents />
      )}
  </>
);
export default Sidebar;
