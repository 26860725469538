import React, { useContext, useEffect, useState } from "react";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import Pagination from "../Pagination";
import AuthContext from "../../context/AuthContext";
import dayjs from "dayjs";
import ODateRangePicker from "../../components/shared/datePicker/ODateRangePicker";
import { useTranslation } from "react-i18next";
import CategoryTable from "./Table";
function Home() {
  const { t } = useTranslation();
  const { logoutUser, user, updatePageName } = useContext(AuthContext);
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10,
  });
  const [addShowModal, setShowModal] = useState(false);
  const [users, setAllUser] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [item, setItem] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sort, setSort] = useState({
    sortKey: "createdAt",
    sortType: "desc",
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);

  const [filterData, setFilterData] = useState({
    category: "",
    searchkey: "",
    startDate: "",
    endDate: "",
    isReset: 0,
    isFilter: false,
  });

  const handleResultProcessing = (data) => {
    const response = data?.results;
    const resultStatus = data?.success;

    setAllUser(response?.docs);

    if (resultStatus) {
      setPaginationObj({
        ...paginationObj,
        page: response.page,
        pageCount: response.totalPages,
        perPageItem: response?.docs.length,
        totalItems: response.totalDocs,
      });
    }
  };
  const manager =
    user?.permission?.find((e) => e.manager === "notification-manager") ?? {};
  const handlePageUpdate = () => {
    if (users?.length <= 1) {
      setPage(page - 1);
      setIsDelete(true);
      setPaginationObj({ ...paginationObj, page: page - 1 });
    }
  };

  const handleError = (error) => {
    console.log("error ", error);
    setPaginationObj({});

    if (error?.response?.status === 401 || error?.response?.status === 409) {
      logoutUser();
    }
  };

  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPage(newPage);
  };
  const getAllNotification = async (data) => {
    try {
      const { category, startDate, endDate, searchkey, isFilter } = filterData;
      const shouldUpdatePage =
        (data?.deletePage && users?.length <= 1) ||
        (isFilter && category && data?.statusChange && users?.length <= 1);

      if (shouldUpdatePage) {
        handlePageUpdate();
      } else {
        setIsDelete(false);
      }
      const payload = {
        page,
        pageSize,
        keyword: searchkey,
      };
      const path = apiPath.getFeedbackList;
      const result = await apiGet(path, payload);
      if (result?.status === 200) {
        handleResultProcessing(result.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    getAllNotification();
  }, [page, filterData, sort]);

  const handleReset = () => {
    setFilterData({
      category: "",
      searchkey: "",
      startDate: "",
      endDate: "",
      isReset: filterData?.isReset + 1,
      isFilter: false,
    });
    setPage(1);
    setIsDelete(true);
    setSearchTerm("");
  };

  const handleDateChange = (start, end) => {
    setPage(1);
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isFilter: true,
    });
    setIsDelete(true);
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: 0,
        searchkey: debouncedSearchTerm || "",
        isFilter: !!debouncedSearchTerm,
      });
      setPage(1);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    console.log("addShowModal :>> ", addShowModal);
  }, [addShowModal]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const handleUserView = (userItem) => {
    setItem(userItem);
    setViewShowModal(true);
    updatePageName(` ${t("VIEW") + " " + t("NOTIFICATION_MANAGER")}`);
  };
  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            
            <CategoryTable
              notifications={users}
              user={user}
              getAllNotification={getAllNotification}
              paginationObj={paginationObj}
              page={page}
              setSort={setSort}
              sort={sort}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
   
    </div>
  );
}
export default Home;
