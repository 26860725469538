import React, { useContext } from 'react'
import { apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty, startCase } from 'lodash'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import AuthContext from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import helper from '../../utils/helpers'
import { BsArrowUpShort } from 'react-icons/bs'
import dayjs from 'dayjs'

const Table = ({
  FAQs,
  getAllFAQ,
  handelEdit,
  paginationObj,
  sort,
  setSort,
  manager
}) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const handelDelete = async item => {
    try {
      const path = apiPath.getFAQs + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        getAllFAQ()
      }
    } catch (error) {
      console.log('error in get all FAQs list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#6c6c6c] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE]  bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                {t('S.NO')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('FAQS_TITLE')}
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (sort.sortKey === 'createdAt' && sort.sortType === 'asc') {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'desc'
                    })
                  } else {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'asc'
                    })
                  }
                }}
              >
                <div className='flex'>
                  <span>{t('CREATED_DATE')}</span>
                  <span>
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_UPDATE_DATE')}
              </th>
              {(manager?.add || user?.role === 'admin') && (
                <>
                  <th scope='col' className='py-3 px-6 text-center'>
                    {t('O_ACTION')}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {FAQs &&
              FAQs?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (paginationObj?.page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {startCase(item?.title)}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {dayjs(item?.updatedAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-l text-gray-900'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(manager?.edit || user?.role === 'admin') && (
                          <li
                            onClick={() => handelEdit(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <button title={t('FAQS_EDIT_FAQS')}>
                              {' '}
                              <AiFillEdit className='w-5 h-5 text-slate-600' />
                            </button>
                          </li>
                        )}
                        <li
                          onClick={e =>
                            helper.alertFunction(
                              `Are you sure want to delete '${item.title}'?`,
                              item,
                              handelDelete,
                              true
                            )
                          }
                          className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                        >
                          <button title={t('DELETE_FAQS')}>
                            {' '}
                            <AiFillDelete className='w-5 h-5 text-red-600' />{' '}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(FAQs) && (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={6}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
