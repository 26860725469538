import React from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import helpers from '../../utils/helpers'
import { Link } from 'react-router-dom'
import { AiFillEdit } from 'react-icons/ai'

const Table = ({ emailTemplate, getEmailTemplate, user, page, manager }) => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 cursor-pointer'>
                <div className='flex justify-left'>
                  <span>Template title</span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div>
                  <span>Subject</span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-right'>
                <div className='flex'>
                  <span>Created date</span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6 text-center'>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {emailTemplate &&
              emailTemplate?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>
                    {`${item?.title}` || 'NA'}{' '}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {item?.subject || 'NA'}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {helpers.formatDateTime(item?.createdAt) || 'NA'}
                  </td>

                  <td className='py-4 px-6 border-l flex justify-center'>
                    {(manager?.edit || user?.role === 'admin') && (
                      <Link
                        to='/emailTemplate/edit'
                        state={{ item }}
                        title={t('EDIT_EMAIL_TEMPLATE')}
                      >
                        <div className=''>
                          <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                        </div>
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            {isEmpty(emailTemplate) ? (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
