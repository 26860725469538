import React, { Fragment, useContext, useEffect, useState } from "react";
import { Transition, Menu } from "@headlessui/react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import OImage from "./reusable/OImage";
import { t } from "i18next";
import { MdNotificationsActive } from "react-icons/md";
import Swal from "sweetalert2";
import { apiGet } from "../utils/apiFetch";
import pathObj from "../utils/pathObj";

const TopNavBar = () => {
  const { logoutUser, user, handleSidebar, pageName, updatePageName } =
    useContext(AuthContext);
  const [dashboardDetails, setDashboardDetails] = useState({});

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#222222",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        logoutUser();
      }
    });
  };
  const getDashboardDetails = async () => {
    try {
      const path = pathObj.getDashboardDetails;
      const result = await apiGet(path);
      setDashboardDetails({ ...dashboardDetails, ...result.data.results });
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const handleNotificationClick = () => {
    setDashboardDetails((prevState) => ({
      ...prevState,
      unReadMessageCount: 0,
    }));
  };

  return (
    <div className="linHeader border-b py-4 px-4 md:px-8 dark:bg-slate-900">
      <header className="bg-white items-center flex dark:bg-slate-900">
        <div
          className="mr-4 dark:text-white relative"
          style={{ zIndex: "99" }}
          onClick={() => handleSidebar()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>

        <div className="page_heading ">
          <h1 className="flex items-center text-lg textSlate font-medium dark:text-white">
            {pageName}
          </h1>
        </div>
        <Menu
          as="div"
          className="relative inline-block text-left hover:text-gradientTo sidebar_dropdown ml-auto"
        >
          <div>
            <Menu.Button className="flex relative items-center w-full  active:text-gradientTo">
              <span title={t("O_NOTIFICATION")}>
                <div className="flex justify-end ">
                  {user?.role === "subadmin" ? (
                    <Link
                      to="/notification-list"
                      onClick={handleNotificationClick}
                      className="mr-4 relative"
                    >
                      <MdNotificationsActive className="w-6 h-6" />
                      {dashboardDetails.unReadMessageCount !== 0 && (
                        <span
                          className="absolute right-[-8px] top-[-8px] text-white flex items-center justify-center w-[22px] h-[22px] rounded-full text-[10px]
                        bg-red-600 block"
                        >
                          {dashboardDetails.unReadMessageCount}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  {/* <Link to='/notification-list' className='mr-4 relative '>
                    {isNotification ? (
                      <MdNotificationsActive className='w-6 h-6' />
                    ) : (
                    <MdNotificationsNone className='w-6 h-6' />
                    )}
                  </Link> */}
                </div>
              </span>

              <div className="profile flex items-center ml-auto md:mr-10 mr-2">
                <figure className="inline-block overflow-hidden rounded-full">
                  <OImage
                    src={user?.profilePic}
                    fallbackUrl="/images/user.jpg"
                    className="w-[40px] h-[40px] inline"
                    alt=""
                  />
                </figure>
                <small className="block text-sm textSlate ml-2 md:block hidden dark:text-white">
                  {user?.firstName + " " + user?.lastName}
                </small>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-10"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 text-left min-w-[200px]  right-2 content divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="">
                <Menu.Item onClick={() => updatePageName("Edit profile")}>
                  {({ active }) => (
                    <Link
                      to="/profile"
                      className={`${
                        active ? "bg-gradientTo text-white" : "text-gray-900"
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      My profile
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item onClick={() => updatePageName("Change password")}>
                  {({ active }) => (
                    <Link
                      to="/change-password"
                      className={`${
                        active ? "bg-gradientTo text-white" : "text-gray-900"
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      Change password
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleLogout}
                      className={`${
                        active ? "bg-gradientTo text-white" : "text-gray-900"
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </header>
    </div>
  );
};

export default TopNavBar;
