import Home from '../pages/Home'
import SharedLayout from '../utils/SharedLayout'
import { useTranslation } from 'react-i18next'
import StaticContent from '../pages/static_content/StaticContent'
import Profile from '../pages/profile/Profile'
import ChangePassword from '../pages/auth/ChangePassword'
import Sidebar from '../components/sidebar/Sidebar'
import BellNotifications from '../pages/notification_manager/BellNotifications'
import Settings from '../pages/settings/Settings'
import Login from '../pages/Login'
import User from '../pages/users/User'
import UserView from '../pages/users/UserView'
import NotificationAdd from '../pages/notification_manager/NotificationAdd'
import Notification from '../pages/notification_manager/NotificationManager'
import EmailTemplate from '../pages/email_template/EmailTemplate'
import FAQ from '../pages/faqs/FAQ'
import ContactUs from '../pages/static_content/ContactUs'
import PrivatePolicy from '../pages/static_content/PrivatePolicy'
import TermsAndConditions from '../pages/static_content/TermsAndConditions'
import AboutUs from '../pages/static_content/AboutUs'
import AddEditEmailTemplate from '../pages/email_template/AddEditEmailTemplate'
import Courses from '../pages/course/Courses'
import AddEditCourses from '../pages/course/AddEditCourses'
import HomeManager from '../pages/sub_category/HomeManager'
import CategoryManager from '../pages/category/CategoryManager'
import MdOutlineAddHome from '../pages/sub_category/AddHome'
import Search from '../pages/search/Home'
import EditSearch from '../pages/search/Edit'
import Feedback from '../pages/feedback/Home'





const UseChange = ({ data }) => {
  const { t } = useTranslation()
  return t(data)
}

const AdminRoutes = {
  // All common routes
  path: '/',
  element: <SharedLayout type={<Sidebar />} />,
  children: [
    {
      path: '/dashboard',
      element: <Home />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/user_manager',
      element: <User />,
      name: (
        <>
          <UseChange data='USER_MANAGER' />
        </>
      )
    },
   
    {
      path: '/static_contents_manager',
      element: <StaticContent />,
      name: (
        <>
          <UseChange data='NAV_STATIC_CONTENTS' />
        </>
      )
    },
    {
      path: '/faqs',
      element: <FAQ />,
      name: (
        <>
          <UseChange data='NAV_FAQS' />
        </>
      )
    },
    {
      path: '/notification_manager',
      element: <Notification />,
      name: (
        <>
          <UseChange data='NOTIFICATION_MANAGER' />
        </>
      )
    },

    {
      path: '/notification_manager/add',
      element: <NotificationAdd />,
      name: (
        <>
          <UseChange data='NOTIFICATION_MANAGER' />
        </>
      )
    },
    {
      path: '/emailTemplate',
      element: <EmailTemplate />,
      name: (
        <>
          <UseChange data='EMAIL_TEMPLATE' />
        </>
      )
    },
    {
      path: '/emailTemplate/edit',
      element: <AddEditEmailTemplate />,
      name: (
        <>
          <UseChange data='EMAIL_TEMPLATE' />
        </>
      )
    },
    {
      path: '/setting',
      element: <Settings />,
      name: (
        <>
          <UseChange data='NAV_SETTINGS' />
        </>
      )
    },
    {
      path: '/profile',
      element: <Profile />,
      name: (
        <>
          <UseChange data='NAV_EARNING_MANAGER' />
        </>
      )
    },
    {
      path: '/change-password',
      element: <ChangePassword />,
      name: (
        <>
          <UseChange data='NAV_EARNING_MANAGER' />
        </>
      )
    },

    {
      path: '/notification-manager',
      element: <Notification />,
      name: (
        <>
          <UseChange data='REPORT_MANAGER' />
        </>
      )
    },

    {
      path: '/notification-list',
      element: <BellNotifications />,
      name: (
        <>
          <UseChange data='O_NOTIFICATION' />
        </>
      )
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/privacy-policy',
      element: <PrivatePolicy />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },

    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },


    {
      path: '/about-us',
      element: <AboutUs />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/',
      element: <Home />,
      name: (
        <>
          <UseChange data='COURSES' />
        </>
      )
    },
    {
      path: '/course',
      element: <Courses />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/category',
      element: <CategoryManager />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/course/add',
      element: <AddEditCourses />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/course/edit/:id',
      element: <AddEditCourses />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    // {
    //   path: '/sub-category',
    //   element: <HomeManager />,
    //   name: (
    //     <>
    //       <UseChange data='NAV_DASHBOARD' />
    //     </>
    //   )
    // },
    {
      path: '/home',
      element: <HomeManager />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/home/add',
      element: <MdOutlineAddHome />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/home/edit/:id',
      element: <MdOutlineAddHome />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/search/',
      element: <Search />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/search/:id',
      element: <EditSearch />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    },
    {
      path: '/feedback',
      element: <Feedback />,
      name: (
        <>
          <UseChange data='NAV_DASHBOARD' />
        </>
      )
    }
  ]
}

export default AdminRoutes
