import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helpers from "../../utils/helpers";
import { useState } from "react";
import SupportView from "./SupportView";

const NotificationTable = ({ notifications, paginationObj, sort, setSort }) => {
  const { t } = useTranslation();
  const [supportView, setSupportView] = useState("");
  const [viewShowModals, setViewShowModals] = useState(false);

  const handleUserViewModal = (element) => {
    setSupportView(element);
    setViewShowModals(true);
};

  const handleSendTo = (item) => {
    let result;

    if (item?.sendTo === "all") {
      result = "All users";
    } else if (item?.sendTo === "subAdmin") {
      result = "All Admin";
    } else {
      result = startCase(`${item?.sendTo.replace("Sub", "")}`) || "N/A";
    }

    return result;
  };

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                {t("O_NOTIFICATION_TITLE")}
              </th>
              <th scope="col" className="py-3 px-6">
                {t("SENDER_DETAILS")}
              </th>

              <th className="py-3 px-6" scope="col">
                {t("O_MESSAGE")}
              </th>

              <th className="py-3 px-6" scope="col">
                {t("SEND_TO")}
              </th>

              <th
                className="py-3 px-6 cursor-pointer"
                scope="col"
                onClick={() => {
                  if (sort.sortKey === "createdAt" && sort.sortType === "asc") {
                    setSort({
                      sortKey: "createdAt",
                      sortType: "desc",
                    });
                  } else {
                    setSort({
                      sortKey: "createdAt",
                      sortType: "asc",
                    });
                  }
                }}
              >
                <div className="flex justify-end">
                  <span>{t("SENT_DATE")}</span>
                  <span>
                    {sort.sortKey === "createdAt" &&
                      sort.sortType === "asc" && (
                        <BsArrowUpShort className="w-4 h-4" />
                      )}
                    {sort.sortKey === "createdAt" &&
                      sort.sortType === "desc" && (
                        <BsArrowUpShort className="w-4 h-4 rotate-180" />
                      )}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  {startCase(item?.title) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                  {item?.senderDetails?.fullName &&
                    startCase(item?.senderDetails?.fullName)}
                  {item?.senderDetails?.email ? (
                    <strong className="text-black block">
                      {item?.senderDetails?.email}
                    </strong>
                  ) : (
                    ""
                  )}
                </td>
                <td className="py-4 px-6 border-r w-[500px]">
                  <div className="line-clamp-2">
                    {item?.description &&
                      item?.description.split(" ").slice(0, 10).join(" ")}
                  </div>

                  {item?.description?.length > 10 && (
                    <button
                      type="button"
                      onClick={() => handleUserViewModal(item)}
                      className="bg-black inline-block mt-2 rounded-md text-white p-2 py-1"
                    >
                      View More
                    </button>
                  )}
                </td>

                <td className="py-4 px-6 border-r">{handleSendTo(item)}</td>
                <td className="py-4 px-6 border-r text-right">
                  {item?.createdAt
                    ? helpers.formatDateTime(item.createdAt)
                    : ""}
                </td>
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {viewShowModals && (
        <SupportView setViewShowModals={setViewShowModals} item={supportView} />
      )}
    </div>
  );
};

export default NotificationTable;
