import React, { useContext, useEffect, useId, useState } from 'react'

import UploadIcon from '../../assets/images/upload.png'
import DeleteIcon from '../../assets/images/deleteIcon.png'
import AddIcon from '../../assets/images/addIcon.png'
import helpers from '../../utils/helpers'
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useToastContext from '../../hooks/useToastContext';
import ErrorMessage from '../../components/ErrorMessage';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash'


export default function AddEditCourses() {
  const { logoutUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const { id } = useParams();
  const generateUniqueId = () => {
    return uuidv4();
  };
  const notification = useToastContext();





  const handleCategoryList = async () => {
    try {
      const path = apiPath.getCategoryKeyValue;
      const result = await apiGet(path);
      if (result?.status === 200) {
        setCategoryList(result?.data?.results)
      }
    } catch (error) {
      console.log("error==>", error)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  }

  const handleSubCategoryList = async (id) => {
    try {
      const path = apiPath.getSubCategoryKeyValue;
      const result = await apiGet(path);
      if (result?.status === 200) {
        setSubCategoryList(result?.data?.results || [])
      }
    } catch (error) {
      console.log("error==>", error)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  }

  useEffect(() => {
    handleCategoryList()
    handleSubCategoryList()

  }, [])

  useEffect(() => {

    if (!isEmpty(location?.state)) {
      let data = location?.state
      setAgeGroup(data?.ageGroup)
      setCarousel(data?.carousel)
      setCategoryChoice(data?.categoryChoice)
      setCustomList(data?.customList)
    }
  }, [location?.state])







  const handleSubmit = async (e) => {
    e.preventDefault()
    let noError = validateForm()
    console.log('noError', noError)

    if (noError) {
      try {
        let payload = {
          ageGroup, carousel, categoryChoice, customList
        };
        if(id){
          payload['id'] = id
        }
        const path = apiPath.addHomeGroup;
        const result = await apiPost(path, payload);
        if (result?.status === 200) {
          navigate('/home')
        }
      } catch (error) {
        handleError(error);
      }
    }
  }
  
  const handleError = (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 409) {
      logoutUser();
    }
  };

  useEffect(() => {
    if (id) {
      handleGetCourseDetails(id)
    }
  }, [id])

  const handleGetCourseDetails = async (id) => {
    try {

      const path = apiPath.addHomeGroup + `/${id}`;
      const result = await apiGet(path);
      if (result?.status === 200) {
        let data = result?.data?.results
        console.log('data', data);


      }
    } catch (error) {
      handleError(error);
    }
  }








  function getFileSizeInMB(file, size, sizeIn) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / sizeIn; // Convert bytes to megabytes
    if (fileSizeInMB.toFixed(2) > size) {
      notification.error(`Uploaded media size is too much high.`)
      return false
    }
    return true
  }

  const [error, setError] = useState({
    ageGroup: false, carousel: [{ image: false, description: false, url: false }], customList: [{ titleId: false, title: false }],
    categoryChoice: [{ carouselId: false }]
  })

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...error };

    // Validate ageGroup
    if (!ageGroup) {
      newFormErrors.ageGroup = true;
      isValid = false;
    } else {
      newFormErrors.ageGroup = false;
    }

    // Validate carousel fields
    carousel.map((item, index) => {
      if (!item?.image || !item?.description || !item?.url) {
        newFormErrors.carousel[index] = {
          image: !item?.image,
          description: !item?.description,
          url: !item?.url
        };
        isValid = false;
      } else {
        newFormErrors.carousel[index] = {
          image: false,
          description: false,
          url: false
        };
      }
    });

    // Validate customList fields
    customList?.forEach((item, index) => {
      if (!item?.title || !item?.titleId || !item?.titleId?.length) {
        newFormErrors.customList[index] = {
          title: !item?.title,
          titleId: !item?.titleId || !item?.titleId?.length
        };
        isValid = false;
      } else {
        newFormErrors.customList[index] = {
          title: false,
          titleId: false
        };
      }
    });

    // Validate categoryChoice fields
    categoryChoice?.forEach((item, index) => {
      if (!item?.categoryId) {
        newFormErrors.categoryChoice[index] = {
          categoryId: !item?.categoryId
        };
        isValid = false;
      } else {
        newFormErrors.categoryChoice[index] = {
          categoryId: false,
          subCategoryId: false
        };
      }
    });

    setError(newFormErrors);
    return isValid;
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'>
          <div className='loading-spinner' />
        </div>
      )
    }
  }

  const [ageGroup, setAgeGroup] = useState(null)
  const [carousel, setCarousel] = useState([{
    id: generateUniqueId(),
    image: null, description: null, url: null
  }])

  const [carouselData, setCarouselData] = useState({
    image: null, description: null, url: null
  })

  const addCarousel = () => {
    const newCarousel = [...carousel, { id: generateUniqueId(), image: null, description: null, url: null }];
    setCarousel(newCarousel);
  };

  const removeCarousel = (e, id) => {
    e.preventDefault()
    const updatedSections = carousel.filter(dict => dict.id !== id);
    setCarousel(updatedSections);
  };


  const handleAgeGroup = (e) => {
    setAgeGroup(e.target.value)
  }

  const handleCarouselImage = async (e, inputId, carouselId, fieldName) => {
    let image = e.target.files[0]
    let sizeCheck = getFileSizeInMB(image, 500, 1024 * 1024)
    if (sizeCheck) {
      let value = await helpers.uploadToS3(image)

      if (value) {
        notification.success('Image uploaded successfully');
      }
      handleCarouselData(carouselId, fieldName, value)
    }
  }

  const handleCarouselData = (carouselId, fieldName, value) => {
    const sectionIndex = carousel.findIndex((section) => section.id === carouselId);
    const updatedSections = [...carousel];
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      [fieldName]: value,
    };

    setCarousel(updatedSections);
  }

  const [customList, setCustomList] = useState([{
    id: generateUniqueId(),
    title: null, titleId: []
  }])


  const addCustomList = () => {
    const newCarousel = [...customList, { id: generateUniqueId(), title: null, titleId: [] }];
    setCustomList(newCarousel);
  };

  const removeCustomList = (e, id) => {
    e.preventDefault()
    const updatedSections = customList.filter(dict => dict.id !== id);
    setCustomList(updatedSections);
  };

  const handleCustomId = (event, customId, fieldName) => {
    const { value } = event.target;
    const newTags = value.split(',')?.map(tag => tag.trim());
    handleCustomListData(customId, fieldName, newTags);
  };

  const handleCustomListData = (customId, fieldName, value) => {
    const sectionIndex = customList.findIndex((section) => section.id === customId);
    const updatedSections = [...customList];
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      [fieldName]: value,
    };
    setCustomList(updatedSections);
  }

  const [categoryChoice, setCategoryChoice] = useState([{
    id: generateUniqueId(),
    categoryId: null, subCategoryId: null
  }])


  const addCategory = () => {
    const newCarousel = [...categoryChoice, { id: generateUniqueId(), title: null, titleId: [] }];
    setCategoryChoice(newCarousel);
  };

  const removeCategory = (e, id) => {
    e.preventDefault()
    const updatedSections = categoryChoice.filter(dict => dict.id !== id);
    setCategoryChoice(updatedSections);
  };



  const handleCategoryChoice = (customId, fieldName, value) => {
    if (fieldName === 'categoryId') {
      const sectionIndex = categoryChoice.findIndex((section) => section.id === customId);
      const updatedSections = [...categoryChoice];
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        [fieldName]: value,
        ['subCategoryList']: subCategoryList?.filter((item) => item?.categoryId == (value)),
      };
      setCategoryChoice(updatedSections);
    } else {
      const sectionIndex = categoryChoice.findIndex((section) => section.id === customId);
      const updatedSections = [...categoryChoice];
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        [fieldName]: value,
      };
      setCategoryChoice(updatedSections);
    }
  }

  return (
    <>
      <div className='w-full m-auto bg-slate-50 p-3 my-4 rounded-md '>
        <form>
          <div className="flex justify-end">
            <button type="submit" onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Submit
            </button>
          </div>
          <div className='flex w-full flex-wrap'>


            <div className='flex flex-col w-full px-4 mb-3'>
              <label className='font-semibold mb-2'>Age Group</label>
              <select name='ageGroup' value={ageGroup} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' onChange={handleAgeGroup}>
                <option value=''>select age group</option>
                <option value={'6-8'}>3-5</option>
                <option value={'6-8'}>6-8</option>
                <option value={'9-12'}>9-12</option>
                <option value={'13-18'}>13-18</option>
                <option value={'19-25'}>19-25</option>
                <option value={'26-40'}>26-40</option>
                <option value={'26-40'}>41-80</option>

              </select>
              {!ageGroup && <ErrorMessage message={'Age group is required.'} />}
            </div>

            <hr />

            <label className='font-semibold mb-2'>Carousel</label>
            {carousel?.map((item, index) => {
              return (
                <div className='w-full' key={index}>
                  <div className='flex flex-col w-full px-4 mb-3'>
                    <input type='file' name='image' className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='upload Image' onChange={(e) => handleCarouselImage(e, `carouselImage${index}`, item?.id, "image")} />
                    <span className='text-sm text-gray-400'>Image uploaded {item?.image ? <a className='cursor-pointer text-blue-500' href={item?.image} target="_blank">CLICK</a> : "500kb (limit 2mb)"}</span>

                    {!item?.image && <ErrorMessage message={'image is required.'} />}
                  </div>



                  <div className='flex flex-col w-full px-4 mb-3'>
                    <input type='text' name='description' value={item.description} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='Description' onChange={(e) => handleCarouselData(item?.id, "description", e.target.value)} />
                    {!item?.description && <ErrorMessage message={'description is required'} />}
                  </div>

                  <div className='flex flex-col w-full px-4 mb-3'>
                    <input type='text' name='url' value={item.url} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='External URL' onChange={(e) => handleCarouselData(item?.id, "url", e.target.value)} />
                    {!item?.url && <ErrorMessage message={'url is required'} />}
                  </div>
                  {carousel?.length > 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button
                        className='bg-transparent flex text-red-500 font-semibold'
                        type='submit'
                        onClick={(e) => removeCarousel(e, item.id)}
                      >
                        <img src={DeleteIcon} width={20} className='mr-2' />
                        Delete
                      </button>
                    </div>
                  )}

                  {index === carousel?.length - 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button className='bg-transparent flex text-red-500 font-semibold' type='submit' onClick={addCarousel}>
                        <img src={AddIcon} width={20} className='mr-2' />
                        Add
                      </button>
                    </div>
                  )}
                </div>
              )
            })}


            <label className='font-semibold mb-2'>Custom List</label>
            {customList?.map((item, index) => {
              return (
                <div className='w-full' key={index}>
                  <div className='flex flex-col w-full px-4 mb-3'>
                    <input type='text' name='title' value={item.title} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='List title' onChange={(e) => handleCustomListData(item?.id, "title", e.target.value)} />
                    {!item?.title && <ErrorMessage message={'Title is required.'} />}
                  </div>

                  <div className='flex flex-col w-full px-4 mb-3'>
                    <input type='text' value={item?.titleId?.join(', ')} onChange={(e) => handleCustomId(e, item?.id, "titleId")} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='#Title id 1' />
                    <div className='mt-2'>
                      {item?.titleId?.map((tag, index) => (
                        <span className='text-sm text-white bg-zinc-600  rounded-sm mr-1 py-1 px-2' key={index}>{tag}</span>
                      ))}
                    </div>
                    {item?.titleId?.length == 0 && <ErrorMessage message={'titleId is required.'} />}
                  </div>




                  {customList?.length > 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button
                        className='bg-transparent flex text-red-500 font-semibold'
                        type='submit'
                        onClick={(e) => removeCustomList(e, item.id)}
                      >
                        <img src={DeleteIcon} width={20} className='mr-2' />
                        Delete
                      </button>
                    </div>
                  )}

                  {index === customList?.length - 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button className='bg-transparent flex text-red-500 font-semibold' type='submit' onClick={addCustomList}>
                        <img src={AddIcon} width={20} className='mr-2' />
                        Add
                      </button>
                    </div>
                  )}
                </div>
              )
            })}

            <label className='font-semibold mb-2'>Category Choices</label>
            {categoryChoice?.map((item, index) => {
              return (
                <div className='w-full' key={index}>
                  <div className='flex flex-col w-full px-4 mb-3'>
                    <select name='categoryId' value={item?.categoryId} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' onChange={(e) => handleCategoryChoice(item?.id, "categoryId", e.target.value)} >
                      <option value=''>select category</option>
                      {categoryList?.map(({ name, _id }, i) => {
                        return (
                          <option value={_id} key={i}>{name}</option>
                        )
                      })}
                    </select>
                    {!item?.categoryId && <ErrorMessage message={'category is required.'} />}
                  </div>
                  <div className='flex flex-col w-full px-4 mb-3'>
                    <select name='subCategoryId' value={item?.subCategoryId} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' onChange={(e) => handleCategoryChoice(item?.id, "subCategoryId", e.target.value)} >
                      <option value=''>select sub-category</option>
                      {item?.subCategoryList?.map(({ name, _id }, i) => {
                        return (
                          <option value={_id} key={i}>{name}</option>
                        )
                      })}
                    </select>

                  </div>

                  {categoryChoice?.length > 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button
                        className='bg-transparent flex text-red-500 font-semibold'
                        type='submit'
                        onClick={(e) => removeCategory(e, item.id)}
                      >
                        <img src={DeleteIcon} width={20} className='mr-2' />
                        Delete
                      </button>
                    </div>
                  )}

                  {index === categoryChoice?.length - 1 && (
                    <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                      <button className='bg-transparent flex text-red-500 font-semibold' type='submit' onClick={addCategory}>
                        <img src={AddIcon} width={20} className='mr-2' />
                        Add
                      </button>
                    </div>
                  )}
                </div>
              )
            })}

          </div>
          <div className='py-3'>
            <div className="flex justify-end">
              <button type="submit" onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>

          </div>
        </form>
        {renderButton()}
      </div>
    </>
  )
}
