import { useRoutes } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'
// routes
import MainRoutes from './MainRoutes'
import AdminRoutes from './AdminRoutes'
import ResetPasswordRoutes from './ResetPasswordRoutes'

export default function CombineRoutes() {
  const { user } = useContext(AuthContext)

  const checkRoles = {
    admin: AdminRoutes,
    subadmin: AdminRoutes
    // "company": CompanyRoutes
  }
  console.log("checkRoles[user?.role]", checkRoles[user?.role], user)
  return useRoutes([user ? checkRoles[user?.role] : MainRoutes, ResetPasswordRoutes], process.env.BASENAME)
}
