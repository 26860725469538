import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helpers from "../../utils/helpers";
import { useState,useContext } from "react";
import { Button } from "@mui/material";
import AuthContext from "../../context/AuthContext";
import ReplyMessage from "./ReplyMessage";
import { apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import useToastContext from "../../hooks/useToastContext";
import dayjs from "dayjs";



const Table = ({ getAllNotification, notifications, paginationObj, sort, setSort }) => {
  const { logoutUser } = useContext(AuthContext);
  const notification = useToastContext();
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const [viewShowModal, setViewShowModal] = useState(false);
  const [message, setMessage] = useState(null);




  const handleReply=(item)=>{
    setFeedback(item)
    setViewShowModal(true)
  }

  const handleError = (error) => {
    console.log("error ", error);
  

    if (error?.response?.status === 401 || error?.response?.status === 409) {
      logoutUser();
    }
  };

  const handleReplyAdd=async()=>{
    console.log("message", message)
    if(message==''||message==null){
      notification.error("message is required")
    console.log("messagezzzzzzzzz", message)

      
    }
    try {
    
      const payload = {
        message,
        id:feedback?._id
      };
      const path = apiPath.replyFeedback;
      const result = await apiPost(path, payload);
      if (result?.status === 200) {
        setViewShowModal(false)
        getAllNotification()
      }
    } catch (error) {
      handleError(error);
    }
  }
  

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                Profile Image
              </th>
              <th className="py-3 px-6" scope="col">
               Full Name
              </th>
              <th scope="col" className="py-3 px-6">
               Designation
              </th>
              <th scope="col" className="py-3 px-6">
               Email
              </th>
              <th scope="col" className="py-3 px-6">
               Mobile
              </th>  <th scope="col" className="py-3 px-6">
              Rating
              </th>  <th scope="col" className="py-3 px-6">
               Message
              </th>  <th scope="col" className="py-3 px-6">
               Comment
              </th>  <th scope="col" className="py-3 px-6">
               TimeStamp
              </th>
              
            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  <img src={item?.profilePic} style={{height:"30px"}}/>
                </td>

                <td className="py-4 px-6 border-r">
                  {startCase(item?.userFullName) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                {startCase(item?.designation) || "N/A"}
                </td>
                <td className="py-4 px-6 border-r">
                {startCase(item?.email) || "N/A"}
                </td> <td className="py-4 px-6 border-r">
                {startCase(item?.mobile) || "N/A"}
                </td> <td className="py-4 px-6 border-r">
                {startCase(item?.rating) || "N/A"}
                </td> 
                <td className="py-4 px-6 border-r">
                {startCase(item?.comment) || "N/A"}
                </td>
                
                <td className="py-4 px-6 border-r">
                {item?.isReply? startCase(item?.replyMessage): 
                <button
                      type="button"
                      onClick={()=>handleReply(item)}
                      className="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                    >
                    Reply  
                      </button>}
                </td>


             
                


                <td className="py-4 px-6 border-r">
                {dayjs(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
                </td>
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {viewShowModal&&<ReplyMessage setViewShowModal={setViewShowModal} handleReplyAdd={handleReplyAdd} setMessage={setMessage}/>}
    </div>
  );
};

export default Table;
