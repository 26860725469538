import React, { useContext, useEffect, useState } from "react";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import Table from "./Table";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

function EmailTemplate() {
  const { t } = useTranslation();
  const { logoutUser, user } = useContext(AuthContext);
  const manager =
    user?.permission?.find((e) => e.manager === "emailTemplate") ?? {};
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 1,
  });
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState({
    sortKey: "createdAt",
    sortType: "desc",
  });
  let pageSize = 1;
  const [searchTerm, setSearchTerm] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [filterData, setFilterData] = useState({
    category: "",
    searchKey: "",
    isReset: false,
    isFilter: false,
  });

  const handleReset = () => {
    setFilterData({
      category: "",
      searchKey: "",
      isReset: true,
      isFilter: false,
    });
    setPage(1);
    setSearchTerm("");
  };

  const handlePaginationAndDelete = (data) => {
    const shouldUpdatePaginationAndDelete =
      (data?.deletePage && emailTemplate?.length >= 1) ||
      (filterData.isFilter &&
        filterData.category &&
        data?.statusChange &&
        emailTemplate?.length >= 1);

    if (shouldUpdatePaginationAndDelete) {
      if (emailTemplate?.length >= 1) {
        setPageAndDelete();
      }
    }
  };

  const setPageAndDelete = () => {
    setPage(page - 1);
    setPaginationObj({ ...paginationObj, page: page - 1 });
  };

  const buildPayload = () => ({
    page,
    limit: pageSize,
    sortKey: sort.sortKey,
    sortType: sort.sortType,
    keyword: searchTerm.trim(),
    status: filterData.category,
  });

  const handleApiCall = async () => {
    const payload = buildPayload();
    const path = apiPath.emailTemplate;

    try {
      const result = await apiGet(path, payload);
      if (result?.status === 200) {
        handleApiResult(result.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiResult = (data) => {
    const response = data.results;
    const resultStatus = data.success;

    setEmailTemplate(response?.docs);
    setPaginationObj({
      ...paginationObj,
      page: resultStatus ? response.page : null,
      pageCount: resultStatus ? response.totalPages : null,
      perPageItem: resultStatus ? response?.docs.length : null,
      totalItems: resultStatus ? response.totalDocs : null,
    });
  };

  const handleApiError = (error) => {
    console.error("error ", error);
    setPaginationObj({});

    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser();
    }
  };

  const getEmailTemplate = async (data) => {
    try {
      handlePaginationAndDelete();
      await handleApiCall();
    } catch (error) {
      console.error("Unhandled error: ", error);
      setPaginationObj({});
    }
  };

  useEffect(() => {
    getEmailTemplate();
  }, [page, pageSize, filterData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm?.trim());
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: false,
        searchkey: debouncedSearchTerm ? debouncedSearchTerm : "",
        isFilter: debouncedSearchTerm ? true : false,
      });
      setPage(1);
    }
  }, [debouncedSearchTerm]);

  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            <form className="border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between">
              <div className="flex flex-wrap  items-center">
                <div className="flex items-center lg:pt-0 pt-3 justify-center">
                  <div className="flex items-center mb-3 ml-3">
                    <label
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                      htmlFor="default-search"
                    >
                      {t("O_SEARCH")}
                    </label>
                    <div className="flex">
                      <div className="relative ">
                        <div className="absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none">
                          {!searchTerm ? (
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-[#A5A5A5] dark:text-gray-40"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          type="search"
                          id="default-search"
                          className="block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Template title"
                          value={searchTerm}
                          title=""
                          required
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleReset()}
                    title={t("O_RESET")}
                    type="button"
                    className="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                  >
                    {t("O_RESET")}
                  </button>
                </div>
              </div>
            </form>
            <Table
              emailTemplate={emailTemplate}
              user={user}
              getEmailTemplate={getEmailTemplate}
              page={page}
              setSort={setSort}
              sort={sort}
              manager={manager}
            />
          </div>
        </div>
      </div>
      <></>
    </div>
  );
}
export default EmailTemplate;
