import React, { useContext, useEffect, useId, useState } from 'react'

import UploadIcon from '../../assets/images/upload.png'
import DeleteIcon from '../../assets/images/deleteIcon.png'
import AddIcon from '../../assets/images/addIcon.png'
import ReactQuill from 'react-quill';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import helpers from '../../utils/helpers'
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useToastContext from '../../hooks/useToastContext';
import ErrorMessage from '../../components/ErrorMessage';




export default function AddEditCourses() {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const { id } = useParams();
  const generateUniqueId = () => {
    return uuidv4();
  };
  const notification = useToastContext();

  const [formData, setFormData] = useState({
    name: '',
    categoryId: "",
    minAge: '',
    maxAge: '',
    type: '',
    subscriptionType: '',
    description: '',
    totalDuration: '',
    titleImage: null,
    link: '',
    title: ""
  });

  const [sections, setSections] = useState([{
    _id: null, id: generateUniqueId(), title: "", sectionImage: "", chapters: [{
      _id: null, id: generateUniqueId(), audioURL: null, chapterName: null, chapterImage: null, chapterDuration: null, experienceURL: null, courseVideo: null, youtubeURL: null, description: null, readURL: null
    }]
  }]);

  const handleCategoryList = async () => {
    try {
      const path = apiPath.getCategoryKeyValue;
      const result = await apiGet(path);
      if (result?.status === 200) {
        setCategoryList(result?.data?.results)
      }
    } catch (error) {
      console.log("error==>", error)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  }

  const handleSubCategoryList = async (id) => {
    try {
      const path = apiPath.getSubCategoryKeyValue + "?id=" + id;
      const result = await apiGet(path);
      if (result?.status === 200) {
        setSubCategoryList(result?.data?.results || [])
      }
    } catch (error) {
      console.log("error==>", error)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  }

  useEffect(() => {
    handleCategoryList()
  }, [])

  useEffect(() => {
    if (formData?.categoryId !== '') {
      handleSubCategoryList(formData?.categoryId)
    }
  }, [formData?.categoryId])


  const addSection = () => {
    const newSections = [...sections, { id: generateUniqueId(), chapters: [{ id: generateUniqueId() }] }];
    setSections(newSections);
  };

  const removeSectionAndChapter = async (payload) => {
    try {


      const path = apiPath.addCourse + 'delete';
      const result = await apiPost(path, payload);
      if (result?.status === 200) {
        handleGetCourseDetails(id)
      }
    } catch (error) {
      handleError(error);
    }
  }

  const removeSection = (id, e, sectionId) => {
    if (sectionId) {
      removeSectionAndChapter({ sectionId })
    }
    e.preventDefault()
    console.log(sections?.length, sections)
    const updatedSections = sections.filter(section => section.id !== id);
    console.log(updatedSections)

    setSections(updatedSections);
  };

  console.log("section", sections)
  const handleSubmit = async (e) => {
    e.preventDefault()
    let noError = handleCheckError()

    if (!noError) {
      console.log("section", sections)
      console.log("highlights", highlights)
      console.log("tags", tags)
      console.log("credits", credits)
      console.log("outComes", outComes)
      console.log("formData", formData, id)
      // return
      try {
        const payload = {
          sections,
          highlights,
          tags,
          credits,
          outComes,
          formData,

        };
        if (id) {
          payload['recordId'] = id
        }
        const path = apiPath.addCourse;
        const result = await apiPost(path, payload);
        if (result?.status === 200) {
          navigate('/course')
        }
      } catch (error) {
        handleError(error);
      }
    }
  }

  const handleError = (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 409) {
      logoutUser();
    }
  };

  useEffect(() => {
    if (id) {
      handleGetCourseDetails(id)
    }
  }, [id])

  const handleGetCourseDetails = async (id) => {
    try {

      const path = apiPath.addCourse + `/${id}`;
      const result = await apiGet(path);
      if (result?.status === 200) {
        let data = result?.data?.results
        console.log('data', data);
        setSections(data?.sections)
        setTags(data?.tags)
        setOutComes(data?.outComes)
        setHighlights(data?.highlights)
        setCredits(data?.credits)
        setFormData({
          totalDuration: data?.totalDuration,
          description: data?.description,
          type: data?.type,
          subscriptionType: data?.subscriptionType,
          categoryId: data?.categoryId,
          subCategoryId: data?.subCategoryId,
          minAge: data?.minAge,
          maxAge: data?.maxAge,
          titleImage: data?.titleImage,
          title: data?.title

        })

      }
    } catch (error) {
      handleError(error);
    }
  }

  const addChapter = (sectionId) => {
    const updatedSections = sections?.map(section => {
      if (section.id === sectionId) {
        const newChapterId = generateUniqueId();
        return {
          ...section,
          chapters: [...section.chapters, { id: newChapterId }]
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const removeChapter = (sectionId, chapterId, _id) => {
    if (_id) {
      removeSectionAndChapter({ chapterId: _id })
    }
    const updatedSections = sections?.map(section => {
      if (section.id === sectionId) {
        const updatedChapters = section.chapters.filter(chapter => chapter.id !== chapterId);
        return { ...section, chapters: updatedChapters };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const [highlights, setHighlights] = useState([{ id: 1, value: '' }]);

  // Function to handle adding a new input field
  const handleAddField = () => {
    const newInputFields = [...highlights];
    newInputFields.push({ id: highlights.length + 1, value: '' });
    setHighlights(newInputFields);
  };

  // Function to handle removing an input field
  const handleRemoveField = (id) => {
    const newInputFields = highlights.filter(field => field.id !== id);
    setHighlights(newInputFields);
  };

  // Function to handle input change for a specific field
  const handleHeighLiteChange = (id, value) => {
    const newInputFields = highlights?.map(field => {
      if (field.id === id) {
        return { ...field, value: value };
      }
      return field;
    });
    setHighlights(newInputFields);
  };


  const [outComes, setOutComes] = useState([{ id: 1, title: '' }]);

  const addOutcomes = () => {
    const newListTitles = [...outComes];
    newListTitles.push({ id: outComes.length + 1, title: '' });
    setOutComes(newListTitles);
  };

  const removeOutcomes = (id) => {
    const newListTitles = outComes.filter(title => title.id !== id);
    setOutComes(newListTitles);
  };

  const handleOutcomesChange = (id, value) => {
    const newListTitles = outComes?.map(title => {
      if (title.id === id) {
        return { ...title, title: value };
      }
      return title;
    });
    setOutComes(newListTitles);
  };

  const [credits, setCredits] = useState([{ id: 1, value: '' }]);

  const addCredit = () => {
    const newCredits = [...credits];
    newCredits.push({ id: credits.length + 1, value: '' });
    setCredits(newCredits);
  };

  const removeCredit = (id) => {
    const newCredits = credits.filter(credit => credit.id !== id);
    setCredits(newCredits);
  };

  const handleCreditChange = (id, value) => {
    const newCredits = credits?.map(credit => {
      if (credit.id === id) {
        return { ...credit, value: value };
      }
      return credit;
    });
    setCredits(newCredits);
  };

  function getFileSizeInMB(file, size, sizeIn) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / sizeIn; // Convert bytes to megabytes
    if (fileSizeInMB.toFixed(2) > size) {
      notification.error(`Uploaded media size is too much high.`)
      return false
    }
    return true
  }



  const handleTitleImage = async (e) => {
    let image = e.target.files[0]
    let sizeCheck = getFileSizeInMB(image, 2, 1024 * 1024)
    if (sizeCheck) {
      let value = await helpers.uploadToS3(image)
      if (value) {
        const fileNameSpan = document.getElementById('titleImage');
        fileNameSpan.textContent = image.name
        notification.success('Image uploaded successfully');
      }
      setFormData({ ...formData, titleImage: value })
    }
  }

  const handleSectionImage = async (e, inputId, fieldName, sectionId) => {
    let image = e.target.files[0]
    let sizeCheck = getFileSizeInMB(image, 2, 1024 * 1024)
    if (sizeCheck) {
      let value = await helpers.uploadToS3(image)
      if (value) {
        const fileNameSpan = document.getElementById(inputId);
        fileNameSpan.textContent = image.name
        notification.success('Image uploaded successfully');
      }
      handleSectionInputChange(sectionId, fieldName, value)
    }
  }

  const handleChapterImage = async (e, inputId, sectionId, chapterId, fieldName) => {
    let image = e.target.files[0]
    let sizeCheck = getFileSizeInMB(image, 500, 1024)
    if (sizeCheck) {
      let value = await helpers.uploadToS3(image)
      if (value) {
        const fileNameSpan = document.getElementById(inputId);
        fileNameSpan.textContent = image.name
        notification.success('Image uploaded successfully');
      }
      handleChapterInputChange(sectionId, chapterId, fieldName, value)
    }
  }

  const handleUploadPDF = async (e, inputId, sectionId, chapterId, fieldName) => {
    let image = e.target.files[0]
    // let sizeCheck = getFileSizeInMB(image, 500, 1024)
    // if (sizeCheck) {
    let value = await helpers.uploadToS3(image)
    if (value) {
      const fileNameSpan = document.getElementById(inputId);
      fileNameSpan.textContent = image.name
      notification.success('Image uploaded successfully');
    }
    handleChapterInputChange(sectionId, chapterId, fieldName, value)
    // }
  }

  const handleVideo = async (e, inputId, sectionId, chapterId, fieldName) => {
    let image = e.target.files[0]
    let sizeCheck = getFileSizeInMB(image, 200, 1024 * 1024)
    if (sizeCheck) {
      let value = await helpers.uploadToS3(image)
      if (value) {
        const fileNameSpan = document.getElementById(inputId);
        fileNameSpan.textContent = image.name
        notification.success('Video uploaded successfully');
      }
      handleChapterInputChange(sectionId, chapterId, fieldName, value)
    }
  }


  const handleChapterInputChange = (sectionId, chapterId, fieldName, value) => {
    const updatedSections = sections?.map(section => {
      if (section.id === sectionId) {
        if (chapterId) {
          const updatedChapters = section.chapters?.map(chapter => {
            if (chapter.id === chapterId) {
              return { ...chapter, [fieldName]: value };
            }
            return chapter;
          });
          return { ...section, chapters: updatedChapters };
        } else {
          return { ...section, [fieldName]: value };
        }
      }
      return section;
    });
    setSections(updatedSections);
  };

  const handleSectionInputChange = (sectionId, fieldName, value) => {
    // Find the index of the section in the sections array
    const sectionIndex = sections.findIndex((section) => section.id === sectionId);

    // Create a copy of the sections array to avoid mutating state directly
    const updatedSections = [...sections];

    // Update the value of the specified field in the corresponding section
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      [fieldName]: value,
    };

    // Update the state with the modified sections array
    setSections(updatedSections);
  };



  const handleCourseDetails = (event) => {
    const { name, value } = event.target;
    if (parseInt(value) < parseInt(formData.minAge)) {
      // Prevent selection if maxAge is less than minAge
      // You can display an error message or handle it in any other way appropriate for your UI
      console.log('Max age cannot be less than min age');
      setError({ ...error, maxAge: 'Max age cannot be less than min age' })
      
    } 

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const [tags, setTags] = useState([]);

  // Function to handle changes in the input field
  const handleTagChange = (event) => {
    const { value } = event.target;
    // Split the input value by commas and trim whitespace from each value
    const newTags = value.split(',')?.map(tag => tag.trim());
    setTags(newTags);
  };

  const [error, setError] = useState(null)

  const handleCheckError = () => {
    let isError = false;
    let errors = {};

    if (!formData?.title) {
      errors['title'] = "Title is required.";
      isError = true
    }

    if (!formData?.titleImage) {
      errors['titleImage'] = "Title image required.";
      isError = true
    }

    if (!formData?.minAge) {
      errors['minAge'] = "Min age required.";
      isError = true
    }

    if (!formData?.maxAge) {
      errors['maxAge'] = "Max age required.";
      isError = true
    }
    if (formData?.maxAge < formData?.minAge) {
      errors['maxAge'] = "'Max age cannot be less than min age'.";
      isError = true
    }

    if (!formData?.type) {
      errors['type'] = "Type required.";
      isError = true
    }
    if (!formData?.subscriptionType) {
      errors['subscriptionType'] = "Subscription type required.";
      isError = true
    }
    if (!formData?.categoryId) {
      errors['categoryId'] = "Category is required.";
      isError = true
    }
    if (!formData?.description) {
      errors['description'] = "Description id required.";
      isError = true
    }
    if (!formData?.totalDuration) {
      errors['totalDuration'] = "Total duration required.";
      isError = true
    }
    if (highlights?.filter((item) => !item?.value)?.length) {
      errors['highlights'] = "Highlights is required.";
      isError = true
    }
    if (outComes?.filter((item) => !item?.title)?.length) {
      errors['outComes'] = "Outcome is required.";
      isError = true
    }
    if (credits?.filter((item) => !item?.value)?.length) {
      errors['credits'] = "Credit is required.";
      isError = true
    }
    if (tags?.length === 0) {
      errors['tags'] = "Tags is required.";
      isError = true
    }



    console.log('errors', errors, outComes);
    setError(errors); // Update state with errors
    return isError;
  };


  return (
    <>
      <div className='w-full m-auto bg-slate-50 p-3 my-4 rounded-md '>
        <form>
          <div className="flex justify-end">
            <button type="submit" onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Submit
            </button>
          </div>
          <div className='flex w-full flex-wrap'>

            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Title Name</label>
              <input type='text' name='title' value={formData.title} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='Name' onChange={handleCourseDetails} />
              <span className='text-sm text-gray-400'>4-32 Character</span>
              {error?.title && <ErrorMessage message={error?.title} />}
            </div>



            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Title Image</label>
              <span className='relative bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'>
                <input type='file' className='absolute top-0 right-0 left-0 bottom-0 opacity-0 ' onChange={handleTitleImage} />
                <span id='titleImage'>Upload Image</span>
                <small className='absolute end-4'><img src={UploadIcon} width={24} /></small>
              </span>
              <span className='text-sm text-gray-400'>Title Image uploaded {formData?.titleImage ? <a className='cursor-pointer text-blue-500' href={formData?.titleImage} target="_blank">CLICK</a> : "200kb (limit 2mb)"}</span>
              {error?.titleImage && <ErrorMessage message={error?.titleImage} />}
            </div>


            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Min Age</label>
              <select name='minAge' value={formData.minAge} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' onChange={handleCourseDetails}>
                <option value=''>select age</option>
                {Array.from({ length: 58 }, (_, index) => index + 3)?.map((age, i) => (
                  <option key={i} value={age}>{age} Year</option>
                ))}
              </select>
              <span className='text-sm text-gray-400'>3-60 numbers drop down</span>
              {error?.minAge && <ErrorMessage message={error?.minAge} />}
            </div>

            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Max Age</label>
              <select className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' name='maxAge' onChange={handleCourseDetails} value={formData.maxAge}>
                <option value=''>select age</option>
                {Array.from({ length: 58 }, (_, index) => index + 3)?.map((age, i) => (
                  <option key={i} value={age}>{age} Year</option>
                ))}
              </select>
              <span className='text-sm text-gray-400'>min age +1 to 60 numbers drop down</span>
              {error?.maxAge && <ErrorMessage message={error?.maxAge} />}
            </div>



            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Type</label>
              <select disabled={formData?.type} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' name='type' onChange={handleCourseDetails} value={formData.type}>
                <option value=''>select option</option>
                <option value={'course'}>Course</option>
                <option value={'title'}>Title</option>

              </select>
              <span className='text-sm text-gray-400'>Title/Course</span>
              {error?.type && <ErrorMessage message={error?.type} />}
            </div>


            <div className='flex flex-col w-1/2 px-4 mb-3'>
              <label className='font-semibold mb-2'>Subscription type</label>
              <select className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' name='subscriptionType' onChange={handleCourseDetails} value={formData.subscriptionType}>
                <option value=''>select option</option>
                <option value='free'>Free</option>
                <option value='pro'>Pro</option>
                <option value='lite'>Lite</option>
                <option value='plus'>Plus</option>
              </select>
              <span className='text-sm text-gray-400'>Free/Lite/Plus/Pro</span>
              {error?.subscriptionType && <ErrorMessage message={error?.subscriptionType} />}
            </div>



            <div className='flex flex-col px-4 w-full mb-3'>
              <label className='font-semibold mb-2'>Category</label>
              <div className='flex gap-8'>
                <div className=' w-1/2'>
                  <select className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12 w-full' name='categoryId' onChange={handleCourseDetails} value={formData.categoryId}>

                    <option value={''}>select category</option>
                    {categoryList?.map(({ name, _id }, i) => {
                      return (
                        <option value={_id} key={i}>{name}</option>
                      )
                    })}

                  </select>
                  {error?.categoryId && <ErrorMessage message={error?.categoryId} />}
                </div>
                <label className='font-semibold mb-2'>Sub Category</label>
                <div className=' w-1/2'>
                  <select className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12 w-full' name='subCategoryId' onChange={handleCourseDetails} value={formData.subCategoryId}>
                    <option value={''}>select sub-category</option>
                    {subCategoryList?.map(({ name, _id }, i) => {
                      return (
                        <option value={_id} key={i}>{name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className='flex flex-col w-full px-4 mb-3'>
              <label className='font-semibold mb-2'>Shareable link</label>
              <input disabled className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' name="link" />
            </div>

          </div>

          {/* Chapter 1 */}
          <div className='py-3'>
            <h3 className='flex flex-col px-4 w-full mb-3 text-xl font-bold'>About section</h3>

            <div className='flex w-full flex-wrap'>
              <div className='flex flex-col w-full px-4 mb-3'>
                <label className='font-semibold mb-2'>Description</label>
                <input type='textarea' name='description' onChange={handleCourseDetails} value={formData.description} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='description' />
                <span className='text-sm text-gray-400'>10-300 characters</span>
                {error?.description && <ErrorMessage message={error?.description} />}
              </div>

              <div className='flex flex-col w-full px-4 mb-3'>
                <label className='font-semibold mb-2'>Total Duration</label>
                <input type='text' name='totalDuration' onChange={handleCourseDetails} value={formData.totalDuration} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='duration' />
                <span className='text-sm text-gray-400'>4-12 characters</span>
                {error?.totalDuration && <ErrorMessage message={error?.totalDuration} />}
              </div>

              <div className='flex flex-col px-4 w-full mb-3'>
                <label className='font-semibold mb-2'>Highlights</label>
                {highlights?.map((field, index) => (
                  <div className='flex gap-8 pe-24 relative mb-2' key={field.id}>
                    <div className='w-full'>
                      <input
                        type='text'
                        placeholder='list title1'
                        className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12 w-full'
                        value={field.value}
                        onChange={(e) => handleHeighLiteChange(field.id, e.target.value)}
                      />
                    </div>
                    <div className='absolute right-4 top-3 gap-4 flex'>
                      {index !== 0 && <button className='bg-transparent border-none' onClick={() => handleRemoveField(field.id)}>
                        <img src={DeleteIcon} width={24} alt='Delete' />
                      </button>}
                      {field.id === highlights[highlights.length - 1].id && (
                        <button className='bg-transparent border-none' onClick={handleAddField}>
                          <img src={'../../assets/images/addIcon.png'} width={24} alt='Add' />
                        </button>
                      )}
                    </div>

                  </div>
                ))}
                {error?.highlights && <ErrorMessage message={error?.highlights} />}
              </div>


              <div className='flex flex-col px-4 w-full mb-3'>
                <label className='font-semibold mb-2'>Outcomes</label>
                {outComes?.map((title, index) => (
                  <div className='input-field mb-2' key={title.id}>
                    <div className='flex gap-8 pe-24 relative'>
                      <div className='w-full'>
                        <input
                          type='text'
                          placeholder='list title'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12 w-full'
                          value={title.title}
                          onChange={(e) => handleOutcomesChange(title.id, e.target.value)}
                        />
                      </div>
                      <div className='absolute right-4 top-3 gap-4 flex'>
                        {index !== 0 && <button className='bg-transparent border-none' onClick={() => removeOutcomes(title.id)}>
                          <img src={DeleteIcon} width={24} alt='Delete' />
                        </button>}
                        {title.id === outComes[outComes.length - 1].id && (
                          <button className='bg-transparent border-none' onClick={addOutcomes}>
                            <img src={'../../assets/images/addIcon.png'} width={24} alt='Add' />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {error?.outComes && <ErrorMessage message={error?.outComes} />}
              </div>

              <div className='flex flex-col px-4 w-full mb-3'>
                <label className='font-semibold mb-2'>Credits</label>
                {credits?.map((credit, index) => (
                  <div className='credit-field mb-2' key={credit.id}>
                    <div className='flex gap-8 pe-24 relative'>
                      <div className='w-full'>
                        <input
                          type='text'
                          placeholder='Enter credit'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12 w-full'
                          value={credit.value}
                          onChange={(e) => handleCreditChange(credit.id, e.target.value)}
                        />
                      </div>
                      <div className='absolute right-4 top-3 gap-4 flex'>
                        {index !== 0 && <button className='bg-transparent border-none' onClick={() => removeCredit(credit.id)}>
                          <img src={DeleteIcon} width={24} alt='Delete' />
                        </button>}
                        {credit.id === credits[credits.length - 1].id && (
                          <button className='bg-transparent border-none' onClick={addCredit}>
                            <img src={'../../assets/images/addIcon.png'} width={24} alt='Add' />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {error?.credits && <ErrorMessage message={error?.credits} />}
              </div>


              <div className='flex flex-col w-full px-4 mb-3'>
                <label className='font-semibold mb-2'>Tags</label>
                <input type='text' value={tags?.join(', ')} onChange={handleTagChange} className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12' placeholder='#Tag1' />
                <div className='mt-2'>
                  {tags?.map((tag, index) => (
                    <span className='text-sm text-white bg-zinc-600  rounded-sm mr-1 py-1 px-2' key={index}>{tag}</span>
                  ))}
                </div>
                {error?.tags && <ErrorMessage message={error?.tags} />}
              </div>

            </div>


            {/* next section */}
            {sections?.map((section, i) => (
              <div className='py-3 bg-amber-50 my-3' key={i}>
                <div className='flex flex-col w-full px-4 mb-3'>
                  <label className='font-semibold mb-2'>Section title</label>
                  <input
                    type='text'
                    className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                    placeholder='Title'
                    value={section.title || ''}
                    onChange={(e) => handleSectionInputChange(section.id, 'title', e.target.value)}
                  />
                </div>
                <div className='flex flex-col w-full px-4 mb-3'>
                  <label className='font-semibold mb-2'>Section Image</label>
                  <span className='relative bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'>
                    <input type='file' name='sectionImage' className='absolute top-0 right-0 left-0 bottom-0 opacity-0 ' onChange={(e) => handleSectionImage(e, `imageSection${i}`, 'sectionImage', section.id)} />
                    <span id={`imageSection${i}`}>Upload Image</span>
                    <small className='absolute end-4'><img src={UploadIcon} width={24} /></small>
                  </span>
                  <span className='text-sm text-gray-400'>Title Image uploaded {section?.sectionImage ? <a className='cursor-pointer text-blue-500' href={section?.sectionImage} target="_blank">CLICK</a> : "200kb (limit 2mb)"}</span>
                </div>
                {section?.chapters?.map((chapter, index) => (
                  <div key={chapter.id}>
                    <div className='flex justify-between items-center p-4 mb-3'>
                      <h3 className='text-xl font-bold'>Chapter {index + 1}</h3>
                      {index !== 0 && (
                        <button
                          className='bg-transparent flex text-red-500 font-semibold'
                          onClick={() => removeChapter(section.id, chapter.id, chapter?._id)}
                          type='button'
                        >
                          <img src={DeleteIcon} width={20} className='mr-2' />Delete chapter
                        </button>
                      )}
                    </div>
                    <div className='flex w-full flex-wrap'>
                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Chapter Name</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='Title'
                          value={chapter.chapterName || ''}
                          name="chapterName"
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'chapterName', e.target.value)}
                        />
                        <span className='text-sm text-gray-400'>4-32 characters</span>
                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Chapter Image</label>
                        <span className='relative bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'>
                          <input type='file' className='absolute top-0 right-0 left-0 bottom-0 opacity-0' name='chapterImage' onChange={(e) => handleChapterImage(e, `chapterImage${index}`, section.id, chapter.id, 'chapterImage')} />
                          <span id={`chapterImage${index}`}>Upload Image</span>
                          <small className='absolute end-4'><img src={UploadIcon} width={24} /></small>
                        </span>
                        <span className='text-sm text-gray-400'>Image upload {chapter?.chapterImage ? <a className='cursor-pointer text-blue-500' href={chapter?.chapterImage} target="_blank" >CLICK</a> : "limit to 500kb"}</span>

                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Duration</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='Duration'
                          value={chapter.chapterDuration || ''}
                          name='chapterDuration'
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'chapterDuration', e.target.value)}
                        />
                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Experience url</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='experienceURL'
                          value={chapter.experienceURL || ''}
                          name='experienceURL'
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'experienceURL', e.target.value)}
                        />
                        <span className='text-sm text-gray-400'>Enter the sketch fab url</span>
                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Youtube url</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='Duration'
                          value={chapter.youtubeURL || ''}
                          name='youtubeURL'
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'youtubeURL', e.target.value)}
                        />
                        <span className='text-sm text-gray-400'>Enter the youtube url</span>
                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Video upload</label>
                        <span className='relative bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'>
                          <input type='file' className='absolute top-0 right-0 left-0 bottom-0 opacity-0 ' name='courseVideo' onChange={(e) => handleVideo(e,
                            `courseVideo${index}`, section.id, chapter.id, 'courseVideo')} />
                          <span id={`courseVideo${index}`}>Video upload</span>
                          <small className='absolute end-4'><img src={UploadIcon} width={24} /></small>
                        </span>
                        <span className='text-sm text-gray-400'>Video uploaded -{chapter?.courseVideo ? <a className='cursor-pointer text-blue-500' href={chapter?.courseVideo} target="_blank" >CLICK</a> : " 50 mb (limit 200 mb)"}</span>

                      </div>

                      <div className=' flex-col w-full px-4 mb-3'>
                        <label className='font-semibold mb-2'>Text file</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={chapter.description || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            handleChapterInputChange(section.id, chapter.id, "description", data)
                          }}
                        />
                      </div>

                      <div className='flex flex-col w-full px-4 mb-3'>
                        <label className='font-semibold mb-2'>PDF/EBOOK</label>
                        <span className='relative bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'>
                          <input type='file' className='absolute top-0 right-0 left-0 bottom-0 opacity-0' name='pdf' onChange={(e) => handleUploadPDF(e, `pdf${index}`, section.id, chapter.id, 'pdf')} />
                          <span id={`pdf${index}`}>Upload File</span>
                          <small className='absolute end-4'><img src={UploadIcon} width={24} /></small>
                        </span>
                        <span className='text-sm text-gray-400'>{chapter?.pdf ? <a className='cursor-pointer text-blue-500' href={chapter?.pdf} target="_blank" >CLICK</a> : ''}</span>

                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Read url</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='Read url'
                          value={chapter.readURL || ''}
                          name='readURL'
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'readURL', e.target.value)}
                        />
                        <span className='text-sm text-gray-400'>Enter wikipedia/ text url</span>
                      </div>

                      <div className='flex flex-col w-1/2 px-4 mb-3'>
                        <label className='font-semibold mb-2'>Audio url</label>
                        <input
                          type='text'
                          className='bg-white py-3 px-4 focus:outline-none border rounded-lg h-12'
                          placeholder='Enter audio url'
                          value={chapter.audioURL || ''}
                          onChange={(e) => handleChapterInputChange(section.id, chapter.id, 'audioURL', e.target.value)}
                        />
                        <span className='text-sm text-gray-400'>Enter audio url</span>
                      </div>
                    </div>

                    {formData?.type === 'course' && index === section.chapters.length - 1 && (
                      <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                        <button
                          className='bg-transparent flex text-red-500 font-semibold'
                          onClick={() => addChapter(section.id)}
                          type='button'
                        >
                          <img src={'../../assets/images/addIcon.png'} width={20} className='mr-2' />
                          Add chapter
                        </button>
                      </div>
                    )}
                  </div>
                ))}
                {i !== 0 && (
                  <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                    <button
                      className='bg-transparent flex text-red-500 font-semibold'
                      type='submit'
                      onClick={(e) => removeSection(section.id, e, section?._id)}
                    >
                      <img src={DeleteIcon} width={20} className='mr-2' />
                      Delete Section
                    </button>
                  </div>
                )}

                {formData?.type === 'course' && i === sections?.length - 1 && (
                  <div className='flex flex-col items-center justify-center w-full px-4 mb-3'>
                    <button className='bg-transparent flex text-red-500 font-semibold' type='submit' onClick={addSection}>
                      <img src={'../../assets/images/addIcon.png'} width={20} className='mr-2' />
                      Add Section
                    </button>
                  </div>
                )}

              </div>
            ))}





            <div className="flex justify-end">
              <button type="submit" onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>

          </div>
        </form >
      </div >
    </>
  )
}
