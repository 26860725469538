import React, { useContext, useEffect, useState } from "react";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import StaticContentTable from "./StaticContentTable";
import Pagination from "../Pagination";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

function StaticContent() {
  const { logoutUser, user } = useContext(AuthContext);
  const manager =
    user?.permission?.find((e) => e.manager === "static_contents_manager") ??
    {};
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10,
  });
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  const [staticContentList, setStaticContentList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [sort, setSort] = useState({
    sortKey: "createdAt",
    sortType: "desc",
  });
  const handleReset = () => {
    setPage(1);
    setSearchTerm("");
    setPageSize(10);
    getStaticContentList(true);
  };
  // get all user function-

  const getStaticContentList = async (reset = false) => {
    try {
      let keyword = reset ? "" : searchTerm;
      const payload = {
        page,
        pageSize,
        keyword: keyword,
        sortKey: sort.sortKey,
        sortType: sort.sortType,
      };

      const path = apiPath.getStaticContent;
      const result = await apiGet(path, payload);

      if (result?.status === 200) {
        const response = result?.data?.results?.docs;
        setStaticContentList(response);
      }
    } catch (error) {
      console.error("error ", error);
      setPaginationObj({});
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  };

  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPage(newPage);
  };

  useEffect(() => {
    getStaticContentList();
  }, [page, sort, searchTerm]);

  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            <form className="border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between">
              <div className="flex flex-wrap items-center">
                <div className="flex items-center md:justify-end px-4">
                  <input
                    type="search"
                    id="default-search"
                    className="block mb-3 w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={t("O_ENTER_TITLE")}
                    value={searchTerm}
                    title=""
                    required
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  {/* <button
                    type="button"
                    onClick={() => handelFilter()}
                    className="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                  >
                    {t("O_FILTER")}
                  </button> */}
                  <button
                    type="button"
                    onClick={() => handleReset()}
                    className="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                  >
                    {t("O_RESET")}
                  </button>
                </div>
              </div>
            </form>
            <StaticContentTable
              staticContentList={staticContentList}
              user={user}
              getStaticContentList={getStaticContentList}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default StaticContent;
