import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helpers from "../../utils/helpers";
import { useState } from "react";

const Table = ({ notifications, paginationObj, sort, setSort }) => {
  const { t } = useTranslation();
  const [supportView, setSupportView] = useState("");
  const [viewShowModals, setViewShowModals] = useState(false);

  const handleUserViewModal = (element) => {
    setSupportView(element);
    setViewShowModals(true);
  };



  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                Search Terms
              </th>
              <th scope="col" className="py-3 px-6">
                Modal 3D URL List
              </th>
              <th scope="col" className="py-3 px-6">
                Read List
              </th>
              <th scope="col" className="py-3 px-6">
                Alternative Search Terms
              </th>
              <th scope="col" className="py-3 px-6">
                Refined
              </th>

            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  {startCase(item?.searchTerms[0]) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                  {startCase(item?.modelUrlList[0]?.embedUrl) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                  {'Read List'}
                </td>
                <td className="py-4 px-6 border-r">
                  {"N/A"}
                </td>

                <td className="py-2 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] text-center">
                  <label
                    className="inline-flex relative items-center cursor-pointer"
                    title={`${item?.status === "active" ? "Active" : "Inactive"}`}
                  >
                    <input
                      type="checkbox"
                      className="sr-only peer"
                    // checked={item?.status}
                    // onChange={(e) => }
                    />
                    <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-gradientTo" />
                  </label>
                </td>
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
