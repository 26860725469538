import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helpers from "../../utils/helpers";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const HomeTable = ({ notifications, paginationObj, sort, setSort }) => {
  const { t } = useTranslation();
  const [supportView, setSupportView] = useState("");
  const [viewShowModals, setViewShowModals] = useState(false);
  const navigate = useNavigate()

  const handleUserViewModal = (element) => {
    setSupportView(element);
    setViewShowModals(true);
  };

  

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                Age Group
              </th>
              <th scope="col" className="py-3 px-6">
               Status
              </th>
              <th scope="col" className="py-3 px-6">
               Action
              </th>
              
            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  {startCase(item?.ageGroup) || "N/A"}
                </td>

               
                <td className="py-4 px-6 border-r">
                  { "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                <AiFillEdit onClick={() => navigate(`/home/edit/${item?._id}`,{ state: item })} />
                </td>
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HomeTable;
