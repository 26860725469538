import ResetPassword from '../pages/auth/ResetPassword'

const ResetPasswordRoutes = {

    // All common routes
    path: '/',
    children: [
        {
            path: '/reset-password/:resetToken',
            element: <ResetPassword />
        },
    ]
}

export default ResetPasswordRoutes
