import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helper from "../../utils/helpers";
import { useState } from "react";
import { apiPatch } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import useToastContext from "../../hooks/useToastContext";

const CategoryTable = ({ notifications, paginationObj, sort, setSort, categoryOption, getAllNotification }) => {
  const { t } = useTranslation();
  const [supportView, setSupportView] = useState("");
  const [viewShowModals, setViewShowModals] = useState(false);
  const notification = useToastContext();

  const handleUserViewModal = (element) => {
    setSupportView(element);
    setViewShowModals(true);
  };

  const handelStatusChange = async (item) => {
    try {
      const payload = {
        status: !item?.status,
      };
      const path = `${apiPath.addCategorySubCategory}/${item?._id}`;
      const result = await apiPatch(path, payload);
      if (result?.status === 200) {
        notification.success(result.data.message);
        getAllNotification();
      }
      // }
    } catch (error) {
      console.log("error in get all users list==>>>>", error.message);
    }
  };
  const renderCheckbox = (item) => (
    <td className="py-2 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] text-center">
      <label
        className="inline-flex relative items-center cursor-pointer"
        title={`${item?.status === "active" ? "Active" : "Inactive"}`}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          checked={item?.status}
          onChange={(e) =>
            helper.alertFunction(
              `Are you sure you want to ${e.target.checked ? "activate" : "inactivate"
              } user ?`,
              item,
              handelStatusChange
            )
          }
        />
        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-gradientTo" />
      </label>
    </td>
  );

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                Name
              </th>
              {categoryOption !== 'category' &&
                <th className="py-3 px-6" scope="col">
                  Category Name
                </th>}
              <th scope="col" className="py-3 px-6">
                Image
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>

            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  {startCase(item?.name) || "N/A"}
                </td>
                {categoryOption !== 'category' &&
                  <td className="py-4 px-6 border-r">
                    {startCase(item?.categoryName) || "N/A"}
                  </td>
                }
                <td className="py-4 px-6 border-r">
                  <img src={item?.image} style={{ height: "30px" }} />
                </td>


                {renderCheckbox(item)}
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryTable;
