import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import AddFAQ from './AddFAQ'
import EditFAQ from './EditFAQ'
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../context/AuthContext'

function FAQ() {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const { logoutUser, user } = useContext(AuthContext)
  const manager = user?.permission?.find(e => e.manager === 'faqs') ?? {}
  const [editShowModal, setEditShowModal] = useState(false)
  const [FAQs, setFAQS] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [item, setItem] = useState('')
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const updatePagination = (pages, paginationObjs) => {
    setPage(pages)
    setPaginationObj({ ...paginationObjs, pages })
  }

  const setPayloadForDeletePage = (currentPayload, pages, pageSizes) => {
    let payload = {
      page: pages,
      pageSize: pageSizes
    }
    if (FAQs?.length >= 1) {
      updatePagination(pages - 1, paginationObj)
    }
    return payload
  }

  const setPayloadForNonDeletePage = (
    currentPayload,
    nonDeletePage,
    nonDeletePageSize,
    sorts
  ) => ({
    page: nonDeletePage,
    pageSize: nonDeletePageSize,
    sortKey: sorts.sortKey,
    sortType: sorts.sortType
  })
  const handleApiResponse = (result, setFAQ, setPaginationObjs) => {
    if (result?.status === 200) {
      const response = result?.data?.results
      const resultStatus = result?.data?.success
      setFAQ(response?.docs)
      setPaginationObjs({
        ...paginationObj,
        page: resultStatus ? response.page : null,
        pageCount: resultStatus ? response.totalPages : null,
        perPageItem: resultStatus ? response?.docs.length : null,
        totalItems: resultStatus ? response.totalDocs : null
      })
    }
  }

  const getAllFAQ = async payload => {
    try {
      let updatedPayload

      if (payload?.deletePage) {
        updatedPayload = setPayloadForDeletePage(payload, page, pageSize)
      } else {
        updatedPayload = setPayloadForNonDeletePage(
          payload,
          page,
          pageSize,
          sort
        )
      }

      const path = apiPath.getFAQs
      const result = await apiGet(path, updatedPayload)

      handleApiResponse(result, setFAQS, setPaginationObj)
    } catch (error) {
      console.error('error in get all FAQs list==>>>>', error.message)
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handelEdit = items => {
    setItem(items)
    setEditShowModal(true)
  }
  useEffect(() => {
    getAllFAQ()
  }, [page, sort])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            <div className=' border-b-[#E3E3E3] grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 '>
              <div className='col-span-2 flex flex-wrap  items-center' />
              <div className='2xl:ml-auto xl:ml-0 lg:pt-0 pt-2'>
                <div className='flex mt-2'>
                  {(manager?.add || user?.role === 'admin') && (
                    <button
                      title={t('FAQS_ADD_FAQS')}
                      class="bg-gradientTo text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                      onClick={() => setShowModal(true)}
                    >
                      + {t('FAQS_ADD_FAQS')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <Table
              FAQs={FAQs}
              getAllFAQ={getAllFAQ}
              handelEdit={handelEdit}
              page={page}
              setSort={setSort}
              sort={sort}
              manager={manager}
              paginationObj={paginationObj}
            />
            {paginationObj?.totalItems !== 0 && (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            )}
          </div>
        </div>
      </div>
      <>
        {showModal && (
          <AddFAQ setShowModal={setShowModal} getAllFAQ={getAllFAQ} />
        )}
        {editShowModal && (
          <EditFAQ
            setEditShowModal={setEditShowModal}
            getAllFAQ={getAllFAQ}
            item={item}
          />
        )}
      </>
    </div>
  )
}

export default FAQ
