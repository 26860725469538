const pathObj = {
  loginUser: "/v1/admin/login",
  getLogs: "/v1/admin/logs",
  refreshToken: "/v1/user/refresh-token",
  seriesList: "/v1/user/match/series",
  matchList: "/v1/user/match/list",
  interests: "/v1/admin/interests",
  changeInterestStatus: "/v1/admin/interests/change-status",
  changeUserStatus: "/v1/admin/user/change-status",
  changeCourseStatus: "/v1/admin/course/change-status",

  changeSubAdminStatus: "/v1/admin/subadmin/change-status",
  getUsers: "/v1/admin/user",
  createUser: "/v1/admin/user",
  userProfile: "/v1/user/profile",
  updateUser: "/v1/admin/user",
  getSubAdmin: "v1/admin/subadmin",
  editSubAdmin: "v1/admin/subadmin",
  forgetPassword: "/v1/admin/forgot-password",
  resetPassword: "/v1/admin/reset-password",
  changePassword: "/v1/admin/change-password",
  userVerify: "/v1/admin/user/verify",
  getCoinListing: "/v1/admin/coin-packs",
  changeCoinPackStatus: "v1/admin/coin-packs/change-status",
  countryManagerListing: "v1/admin/country",
  changeCountryStatus: "/v1/admin/country/change-status",
  editProfile: "/v1/admin/edit-profile",
  postListing: "/v1/admin/posts",
  changePostStatus: "/v1/admin/posts/change-status",
  getStaticContent: "/v1/admin/static-content",
  dailyRewardListing: "/v1/admin/daily-reward/",
  changeRewardStatus: "/v1/admin/daily-reward-change-status",
  updateSettings: "/v1/admin/setting",
  emailTemplate: "/v1/admin/email-template",
  emailStatus: "/v1/admin/email-template/change-status",
  notificationToggle: "/v1/admin/notification-toggle",
  changeContentStatus: "/v1/admin/static-content/change-status",
  getFAQs: "/v1/admin/faqs",
  changeFAQStatus: "/v1/admin/faqs/status",
  categoryListing: "/v1/admin/category",
  giftListing: "/v1/admin/gift",
  earningListing: "v1/admin/earning",
  giftAddCategory: "v1/admin/category/add-category",
  giftEditCategory: "v1/admin/category",
  categoryListWithoutPage: "/v1/admin/gift/category-list",
  addGift: "/v1/admin/gift/add-gift",
  changeCategoryStatus: "/v1/admin/gift/status",
  editGift: "/v1/admin/gift",
  coinPurchaseListing: "/v1/admin/coin-purchase",
  getDashboardDetails: "/v1/admin/dashboard",
  videoListing: "/v1/admin/videos",
  changeVideoStatus: "/v1/admin/videos/change-status",
  reportAbuseListing: "v1/admin/report-abuse",
  categoryStatus: "v1/admin/category/change-status",
  getSound: "/v1/admin/sounds",
  addSound: "/v1/admin/sounds",
  widgetsList: "/v1/admin/widgets/list",
  stickers: "/v1/admin/stickers",
  stickerStatus: "/v1/admin/stickers/status",
  effects: "/v1/admin/effects",
  effectStatus: "/v1/admin/effects/status",
  filter: "/v1/admin/filter",
  filterStatus: "/v1/admin/filter/status",
  effectListSuggestion: "/v1/admin/effects/suggestion/effectList",
  verficationDetail: "v1/admin/user/verification-details",
  report: "/v1/admin/report",
  widgets: "/v1/admin/widgets",
  widgetStatus: "/v1/admin/widgets/status",
  getStaticContentList: "/v1/admin/getStaticContent",
  widgetListSuggestion: "/v1/admin/widgets/suggestion/widgetList",
  downloadReport: "/v1/admin/report/download",
  notifications: "v1/admin/notification",
  allNotificationUser: "/v1/admin/user",
  allNotificationUserList: "/v1/admin/user/key-value",
  fetchLatestSubAdminList: "/v1/admin/subadmin/key-value",
  getBellNotification: "v1/admin/notification/admin",
  changeSoundStatus: "/v1/admin/sounds/status",
  hitMapList: "/v1/admin/heat-map",
  getIndustry: "/v1/admin/industry",
  AddIndustry: "/v1/admin/industry",
  changeIndustryStatus: "/v1/admin/industry/change-status",
  editIndustry: "/v1/admin/industry",
  getOrganization: "/v1/admin/organization",
  changeOrganizationStatus: "/v1/admin/organization/change-status",
  AddOrganization: "/v1/admin/organization",
  editOrganization: "/v1/admin/organization",
  getSupport: "/v1/admin/support",
  replySupport: "/v1/admin/support",
  addCategorySubCategory: "/v1/admin/category/",
  getCategory: "/v1/admin/category/",
  getFeedbackList: "/v1/admin/feedback",
  replyFeedback: "/v1/admin/feedback/reply",


  getSearchList: "/v1/admin/search/",

  getCategoryKeyValue: "/v1/admin/category/key-value",
  getSubCategoryKeyValue: "/v1/admin/category/sub-category-key-value",

  addCourse: "/v1/admin/course/",
  addHomeGroup: "/v1/admin/home/",




};

export default pathObj;
