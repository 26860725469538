import { useContext, useEffect, useState } from "react";
import { apiGet, apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import ReactQuill from "react-quill";
import useToastContext from "../../hooks/useToastContext";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import OInputField from "../../components/reusable/OInputField";
import formValidation from "../../utils/formValidation";
import { preventMaxInput } from "../../utils/validations";

const AboutUs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      title: location?.state?.title,
    },
  });
  const [content, setContent] = useState("");
  const notification = useToastContext();
  const { user, updatePageName, logoutUser } = useContext(AuthContext);

  const [pageType] = location?.state?.pageType;

  const updateStaticContent = async () => {
    try {
      const formValue = getValues();
      const data = {
        title: formValue?.title?.trim(),
        content,
      };
      const path = apiPath.getStaticContent + "?" + "slug=about-us";
      const result = await apiPut(path, data);
      if (result?.status === 200) {
        notification.success(result?.data?.message);
        getStaticContent();
      }
    } catch (error) {
      console.error("error in get all FAQs list=>>>>", error.message);
    }
  };
  const manager =
    user?.permission?.find((e) => e.manager === "static_contents_manager") ??
    {};

  const getStaticContent = async () => {
    try {
      const path = apiPath.getStaticContent;
      const payload = {
        slug: "about-us",
      };
      const result = await apiGet(path, payload);
      if (result?.status === 200) {
        const response = result?.data?.results.docs[0];
        setContent(response?.content);
      }
    } catch (error) {
      console.error("error in get all FAQs list=>>>>", error.message);
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser();
      }
    }
  };
  useEffect(() => {
    getStaticContent();
  }, []);

  useEffect(() => {
    updatePageName(t("NAV_ABOUT_US"));
  }, []);
  return (
    <div>
      <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            <div className=" border-b-[#E3E3E3] grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 ">
              <div className="col-span-2 flex items-center flex-wrap" />
            </div>

            <div className="shadow-md p-5 ">
              <h1>
                {t("STATIC_PAGE_TITLE")}
                <span style={{ color: "red" }}>*</span>
              </h1>

              <OInputField
                type="text"
                wrapperClassName="relative z-0 mb-6 w-full group"
                maxLength={200}
                onInput={(e) => preventMaxInput(e, 200)}
                name="title"
                autoFocus
                register={register("title", formValidation["title"])}
                errors={errors}
              />
              <h1>
                {t("DESCRIPTION")}
                <span style={{ color: "red" }}>*</span>
              </h1>
              <ReactQuill value={content} onChange={setContent} />
            </div>
          </div>
          {/* <p style={{ marginTop: '10px', marginLeft: '10px' }}>
            {t('LAST_UPDATE')}:- {dayjs(updateDate).format('llll')}
          </p> */}
          {pageType === "e" && (
            <div className="2xl:ml-auto xl:ml-0 lg:pt-0 pt-2">
              <div className="flex">
                {(manager?.edit || user?.permission?.length === 0) && (
                  <button
                    title={t("O_SAVE")}
                    className="bg-gradientTo text-sm px-8 mt-3 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2"
                    onClick={handleSubmit(updateStaticContent)}
                  >
                    {t("O_SAVE")}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
