import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowUpShort } from "react-icons/bs";
import helpers from "../../utils/helpers";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import helper from "../../utils/helpers";
import { apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/pathObj";
import useToastContext from "../../hooks/useToastContext";


const CourseTable = ({ notifications, paginationObj, sort, setSort, getCourseList }) => {
  const { t } = useTranslation();
  const notification = useToastContext();
  const [supportView, setSupportView] = useState("");
  const [viewShowModals, setViewShowModals] = useState(false);
  const navigate = useNavigate()
  const handleUserViewModal = (element) => {
    setSupportView(element);
    setViewShowModals(true);
  };

  const handleSendTo = (item) => {
    let result;

    if (item?.sendTo === "all") {
      result = "All users";
    } else if (item?.sendTo === "subAdmin") {
      result = "All Admin";
    } else {
      result = startCase(`${item?.sendTo.replace("Sub", "")}`) || "N/A";
    }

    return result;
  };

  const handelStatusChange = async (item) => {
    try {
      const payload = {
        status: item?.status === "inactive" ? "active" : "inactive",
      };
      const path = `${apiPath.changeCourseStatus}/${item?._id}`;
      const result = await apiPut(path, payload);
      if (result?.status === 200) {
        notification.success(result.data.message);
        getCourseList();
      }
      // }
    } catch (error) {
      console.log("error in get all users list==>>>>", error.message);
    }
  };

  const renderCheckbox = (item) => (
    <td className="py-2 px-4 border-r dark:border-[#ffffff38] dark:border-[#ffffff38] text-center">
      <label
        className="inline-flex relative items-center cursor-pointer"
        title={`${item?.status === "active" ? "Active" : "Inactive"}`}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          checked={item?.status === "active"}
          onChange={(e) =>
            helper.alertFunction(
              `Are you sure you want to ${
                e.target.checked ? "activate" : "inactivate"
              } user ?`,
              item,
              handelStatusChange
            )
          }
        />
        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-gradientTo" />
      </label>
    </td>
  );

  return (
    <div className="p-3">
      <div className="overflow-x-auto relative rounded-lg border">
        <table className="w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                {t("S.NO")}
              </th>
              <th className="py-3 px-6" scope="col">
                Title
              </th>
              <th scope="col" className="py-3 px-6">
                Type
              </th>

              <th className="py-3 px-6" scope="col">
                Subscription Type
              </th>

              <th className="py-3 px-6" scope="col">
                Min Age
              </th>

              <th className="py-3 px-6" scope="col">
                Max Age
              </th>
              <th className="py-3 px-6" scope="col">
                Total Views
              </th>
             
              <th className="py-3 px-6" scope="col">
                Author Name
              </th>
            

              <th className="py-3 px-6" scope="col">
                Category Name
              </th>
              <th className="py-3 px-6" scope="col">
                Sub Category Name
              </th>
              <th className="py-3 px-6" scope="col">
                Subscription Type
              </th>
              <th className="py-3 px-6" scope="col">
                Rating
              </th>
              <th className="py-3 px-6" scope="col">
                Reviews
              </th>




              <th className="py-3 px-6" scope="col">
                Status
              </th>
              <th className="py-3 px-6" scope="col">
                Last Updated
              </th>
              <th className="py-3 px-6" scope="col">
                Edit
              </th>

            </tr>
          </thead>
          <tbody>
            {notifications?.map((item, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-6 border-r font-medium text-gray-900  dark:text-white"
                >
                  {i + 1 + 10 * (paginationObj?.page - 1)}
                </th>
                <td className="py-4 px-6 border-r">
                  {startCase(item?.title) || "N/A"}
                </td>
                <td className="py-4 px-6 border-r">
                  {(item?.type) || "N/A"}
                </td>
                <td className="py-4 px-6 border-r">
                  {(item?.subscriptionType) || "N/A"}
                </td>
                <td className="py-4 px-6 border-r">
                  {(item?.minAge) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                  {(item?.maxAge) || "N/A"}
                </td>

                <td className="py-4 px-6 border-r">
                  {(item?.totalViews) || 0}
                </td>

                <td className="py-4 px-6 border-r">
                  {(item?.userDetails?.fullName) || 0}
                </td>
              
                <td className="py-4 px-6 border-r">
                  {item?.categoryName}
                </td>


                <td className="py-4 px-6 border-r">
                {item?.subCategoryName}
                </td>

                <td className="py-4 px-6 border-r">
                {item?.subscriptionType}
                </td>

                <td className="py-4 px-6 border-r">
                {item?.averageRating}
                </td>

                <td className="py-4 px-6 border-r">
                 {item?.reviews?.map((element, index) => `${index + 1}. ${element}`).join(", ")}
                </td>

                



                <td className="py-4 px-6 border-r">
                  {item?.status}
                </td>

               


                {/* <td className="py-4 px-6 border-r">{handleSendTo(item)}</td> */}
                <td className="py-4 px-6 border-r text-right">
                  {item?.createdAt
                    ? helpers.formatDateTime(item.updatedAt)
                    : ""}
                </td>

                <td className="py-4 px-6 border-r">
                  <AiFillEdit onClick={() => navigate(`/course/edit/${item?._id}`)} />

                  {renderCheckbox(item)}
                </td>
              </tr>
            ))}
            {isEmpty(notifications) && (
              <tr className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6 border-r" colSpan={6}>
                  {t("O_NO_RECORD_FOUND")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default CourseTable;
